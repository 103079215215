<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Demographics List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter demographics..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.demosManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div v-if="store.user.id === 0">
						<p class="bold" style="margin: 20px 0 10px;">Global Demographics</p>
						<div v-for="c in filteredAllUsers" :key="c.id" class="jli">
							<div>
								<span class="jname">{{c.name}}</span>
								<span v-if="store.user.isadmin === 1" class="jup"> (UID: {{c.userid}}, SUID: {{c.survid}})</span>
							</div>
							<div class="" style="display: flex; justify-content: space-between; width: 100px;">
								<span class="jdel" @click="delDemo(c.id)" title="Delete demo">DELETE</span>
							</div>
						</div>
					</div>
					<div>
						<p class="bold" style="margin: 20px 0 10px;">User Saved Demographics</p>
						<DemoManageGroup :demos="filteredThisUser" v-on:deletedemo="delDemo($event)"></DemoManageGroup>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DemoManageGroup from "@/components/demos/DemoManageGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DemosManage",
	components: {
		DemoManageGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			demoslist: [],
			filterText: '',
		}
	},
	computed: {
		filteredThisUser() {
			let demos = this.store.demos.filter(item => item.userid === this.store.user.id);
			if(this.filterText.length > 0) {
				return demos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return demos;
		},
		filteredAllUsers() {
			let demos = this.store.demos.filter(item => item.userid === this.store.user.id);
			if(this.store.user.isadmin === 1) {
				demos = this.store.demos.filter(item => item.userid === 0);
			}
			if(this.filterText.length > 0) {
				return demos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return demos;
		},
	},
	methods: {
		delDemo(cid) {
			let c = window.confirm("Are you sure you wish to delete this demographic?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "demo-delete",
					JSON.stringify({
						userob: self.store.user,
						demoid: cid,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.deldemoid) {
						self.store.demos = self.store.demos.filter(item => item.id !== ret.deldemoid);
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>

</style>