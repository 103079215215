<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Create New Custom Demographic</span>
				<span class="closer" @click="store.demoNewOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div>
						<p style="font-weight: bold; margin-bottom: 5px;">Demo Name </p>
						<input type="text" v-model="editDemoName" style="width: 300px; padding: 5px;" />
						<span @click="saveDemo" class="primary-button" style="margin: 0 20px 0 10px; display: inline-block;">Save</span>
						<input type="checkbox" v-model="showString" />
						<span style="font-size: 10px; margin: 0 10px;">View Demo String</span>
						<span @click="clearAll" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #28A; text-decoration: underline">Reset</span>
					</div>
					<div v-if="showString" style="padding: 10px 0;">
						<input style="width: 100%; padding: 5px;" type="text" v-model="demoQstring" />
					</div>

					<!-- for standard demographics -->

					<div v-if="store.demoNewData.survid === 0">
						<br/><span class='dhead'>Aged between <input type='number' v-model="agelow" min='10' /> and <input type='number' v-model="agehigh" max='99' /></span><br/>
						<span style='font-size: 11px;'>Maximum age 99.  Eg for 10+ set aged between '10 and 99'</span><br/>

						<span class="dhead">Gender</span><br/>
						<div v-for="(g, index) in genderList" :key="index+'_gender'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

						<span class="dhead">Grocery Buyer</span><br/>
						<div v-for="(g, index) in grocbuyerList" :key="index+'_grocbuyer'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

						<span class="dhead">Children In Home</span><br/>
						<div v-for="(g, index) in kidsList" :key="index+'_kids'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

						<span class="dhead">Occupation Status (not available in surveys prior to 2018)</span><br/>
						<div v-for="(g, index) in occstatusList" :key="index+'_occstatus'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

						<span class="dhead">Occupation Group (not available in surveys prior to 2018)</span><br/>
						<div v-for="(g, index) in occgroupList" :key="index+'_occgroup'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

						<span class="dhead">Occupation Industry (not available in surveys prior to 2018)</span><br/>
						<div v-for="(g, index) in occindustryList" :key="index+'_occindustry'">
							<select v-model="g.selection" @change="generateDemoString" :class="{greenback : g.selection === 'include', redback : g.selection === 'exclude'}">
								<option v-for="o in selOptions" :value="o" :key="o">{{ucfirst(o)}}</option>
							</select>
							<span class="labeller">{{g.name}}</span>
						</div>

					</div>

					<div v-if="store.demoNewData.survid > 0" style="padding: 10px 0;">
						<div v-if="survjson.survid > 0">
							<div v-for="q in survjson.questions" :key="q.qid">
								<div v-if="q.qid === survjson.rrAssignedQ.area" style="margin-bottom: 20px;">
									<div v-if="q.qid === survjson.rrAssignedQ.area" style="margin: 0 0 10px;">
										<p style="font-size: 12px;">Select the "View Demo String" box and enter as per following format:
											<span style='font-weight: bold'>AnsQ{{q.qid}}='1'</span> or <span style='font-weight: bold'>AnsQ{{q.qid}}='1' OR AnsQ{{q.qid}}='2'</span>
											where 1 and/or 2 is the value of the region/s listed below that you wish to include.</p>
									</div>
									<p class="bold">AnsQ{{q.qid}}: <span v-html="q.qtext"></span></p>
									<p v-for="opt in q.options" :key="opt.optid">{{opt.optid}}: {{opt.opttext}}</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DemoCreate",
	components: {},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			demoQstring: '',
			showString: false,

			editDemoName: '',

			agelow: '',
			agehigh: '',

			selOptions: ['','include','exclude'],
			genderList: [
				{val: 1, name: 'Male', qtype: 'Radio', selection: ''},
				{val: 2, name: 'Female', qtype: 'Radio', selection: ''},
			],
			grocbuyerList: [
				{val: 1, name: 'Yes', qtype: 'Radio', selection: ''},
				{val: 2, name: 'No', qtype: 'Radio', selection: ''},
			],
			kidsList: [
				{val: 1, name: 'Yes', qtype: 'Radio', selection: ''},
				{val: 2, name: 'No', qtype: 'Radio', selection: ''},
			],
			occstatusList: [
				{val: 1, name: 'Full Time', qtype: 'Radio', selection: ''},
				{val: 2, name: 'Part Time', qtype: 'Radio', selection: ''},
				{val: 3, name: 'Casual / Temporary', qtype: 'Radio', selection: ''},
				{val: 4, name: 'Home Duties / Stay At Home Parent', qtype: 'Radio', selection: ''},
				{val: 5, name: 'Not Employed', qtype: 'Radio', selection: ''},
				{val: 6, name: 'Prefer Not To Say', qtype: 'Radio', selection: ''},
			],
			occgroupList: [
				{val: 1, name: 'Managers & Administrators', qtype: 'Radio', selection: ''},
				{val: 2, name: 'Professionals', qtype: 'Radio', selection: ''},
				{val: 3, name: 'Sales / Clerk & Para-professional', qtype: 'Radio', selection: ''},
				{val: 4, name: 'Tradespersons', qtype: 'Radio', selection: ''},
				{val: 5, name: 'Plant / Machine Operators & Drivers', qtype: 'Radio', selection: ''},
				{val: 6, name: 'Labourers & Related Workers', qtype: 'Radio', selection: ''},
				{val: 7, name: 'Unemployed / Looking for Work', qtype: 'Radio', selection: ''},
				{val: 8, name: 'Student / Under School Age', qtype: 'Radio', selection: ''},
				{val: 9, name: 'Not in Paid Employment', qtype: 'Radio', selection: ''},
				{val: 10, name: 'Retired or Aged Pension', qtype: 'Radio', selection: ''},
				{val: 11, name: 'Prefer not to answer', qtype: 'Radio', selection: ''},
			],
			occindustryList: [
				{val: 1, name: 'Agriculture', qtype: 'Radio', selection: ''},
				{val: 2, name: 'Mining', qtype: 'Radio', selection: ''},
				{val: 3, name: 'Manufacturing', qtype: 'Radio', selection: ''},
				{val: 4, name: 'Electricity, Gas and Water', qtype: 'Radio', selection: ''},
				{val: 5, name: 'Construction', qtype: 'Radio', selection: ''},
				{val: 6, name: 'Wholesale', qtype: 'Radio', selection: ''},
				{val: 7, name: 'Retail', qtype: 'Radio', selection: ''},
				{val: 8, name: 'Transport and Storage', qtype: 'Radio', selection: ''},
				{val: 9, name: 'Communication', qtype: 'Radio', selection: ''},
				{val: 10, name: 'Finance and Insurance', qtype: 'Radio', selection: ''},
				{val: 11, name: 'Property and Business Services', qtype: 'Radio', selection: ''},
				{val: 12, name: 'Public Administration and Defence', qtype: 'Radio', selection: ''},
				{val: 13, name: 'Community Services', qtype: 'Radio', selection: ''},
				{val: 14, name: 'Recreation and Personal', qtype: 'Radio', selection: ''},
				{val: 15, name: 'Prefer not to answer', qtype: 'Radio', selection: ''},
			],

			survjson: {},
		}
	},
	computed: {
		activeSurvId() {
			return this.store.demoNewData.survid;
		}
	},
	methods: {
		generateDemoString() {
			let thestring = '';

			if(this.agelow !== "" && this.agehigh === '') {
				thestring = 'agebroad' + ">='"+this.agelow+"'";
			}
			if(this.agelow === '' && this.agehigh !== "") {
				thestring = 'agebroad' + "<='"+this.agehigh+"'";
			}
			if(this.agelow !== "" && this.agehigh !== "") {
				thestring = "("+ 'agebroad' + ">='"+this.agelow+"' AND " + 'agebroad' + "<='"+this.agehigh+"')";
			}

			let radios = [];
			let mystr = this.getRadioString(this.genderList, 'gender');
			if(mystr.length > 0) radios.push(mystr);
			mystr = this.getRadioString(this.grocbuyerList, 'grocbuyer');
			if(mystr.length > 0) radios.push(mystr);
			mystr = this.getRadioString(this.kidsList, 'kids');
			if(mystr.length > 0) radios.push(mystr);
			mystr = this.getRadioString(this.occstatusList, 'occstatus');
			if(mystr.length > 0) radios.push(mystr);
			mystr = this.getRadioString(this.occgroupList, 'occgroup');
			if(mystr.length > 0) radios.push(mystr);
			mystr = this.getRadioString(this.occindustryList, 'occindustry');
			if(mystr.length > 0) radios.push(mystr);

			if(radios.length > 0 && thestring.length > 0) {
				thestring += " AND " + radios.join(" AND ");
			}
			else if(radios.length > 0 && thestring.length === 0) {
				thestring += radios.join(" AND ");
			}
			this.demoQstring = thestring;
		},
		getRadioString(arrlist, keyname) {
			let includes = []
			let excludes = []
			for(let i=0; i<arrlist.length; i++) {
				let myitem = arrlist[i];
				if(myitem.selection.length > 0) {
					if(myitem.selection === 'include') {
						includes.push(keyname + "='"+myitem.val+"'")
					}
					else if(myitem.selection === 'exclude') {
						excludes.push(keyname + "!='"+myitem.val+"'")
					}
				}
			}
			let str = ''
			if(includes.length > 0) str += "(" + includes.join(" OR ") + ")" //includes for radios we want ANY of them (OR)
			if(excludes.length > 0) {
				if(str.length > 0) str += ' AND '
				str += "(" + excludes.join(" AND ") + ")" //excludes for radios we want veto so NONE of them (AND)
			}
			return str
		},
		clearRadio(arrlist) {
			for(let i=0; i<arrlist.length; i++) {
				arrlist[i].selection = '';
			}
		},
		clearAll() {
			this.clearRadio(this.genderList);
			this.clearRadio(this.grocbuyerList);
			this.clearRadio(this.kidsList);
			this.clearRadio(this.occstatusList);
			this.clearRadio(this.occgroupList);
			this.clearRadio(this.occindustryList);
			this.agelow = '';
			this.agehigh = '';
			this.demoQstring = '';
		},
		saveDemo() {
			if(this.editDemoName.length === 0) {
				alert('Please enter a name for your demographic');
				return false;
			}
			if(this.demoQstring.length === 0) {
				alert('Please make some selections');
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			axios.post(
				self.store.apiroot + "demo-save",
				JSON.stringify({
					userob: self.store.user,
					dname: self.editDemoName,
					dstring: self.cleanDemoString(self.demoQstring),
					survid: self.store.demoNewData.survid,
					ownerId: self.store.demoNewData.userid,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
				}
				if (ret.demos) {
					self.store.demos = ret.demos;
				}
				self.store.showLoader = false;
				self.store.demoNewOverlayShown = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		cleanDemoString(str) {
			let dstring = str.replace(/=/g,"eq");
			dstring = dstring.replace(/>/g,"gt");
			dstring = dstring.replace(/</g,"lt");
			dstring = dstring.replace(/OR/g,"UU");
			dstring = dstring.replace(/AND/g,"AA");
			dstring = dstring.replace(/'/g,"ap");
			dstring = dstring.replace(/!/g,"ex");
			dstring = encodeURIComponent(dstring);
			return dstring;
		},
		getQuestionList() {
			let self = this;
			if(self.store.demoNewData.survid > 0) {
				self.store.showLoader = true;
				axios.post(
					self.store.apiroot + "demo-get-survquestions",
					JSON.stringify({
						userob: self.store.user,
						survid: self.store.demoNewData.survid,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) { //handle returned objects for lists
						self.showKalert(ret.resp)
					}
					if (ret.survey) {
						self.survjson = ret.survey;
					}
					self.store.showLoader = false;
				}).catch(function (error) {
					console.log(error);
					self.store.showLoader = false;
				});
			}
		},
	},
	watch: {
		agelow() { this.generateDemoString(); },
		agehigh() { this.generateDemoString(); },
	},
	mounted() {
		if(this.activeSurvId > 0) {
			this.getQuestionList();
			this.showString = true;
		}
	}
}
</script>

<style scoped>
.dhead {
	font-weight: bold;
	line-height: 1.4;
	margin-top: 15px;
	margin-bottom: 5px;
	display: inline-block;
}
select {
	margin-right: 10px;
	margin-bottom: 5px;
}
.greenback {
	background-color: #00BB00;
	color: #FFF;
}
.redback {
	background-color: #E80042;
	color: #FFF;
}
</style>