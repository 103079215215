<template>
	<div class="rtblHolder">

		<TableHeaderContent :filtereddata="filteredData" :objin="objin"></TableHeaderContent>

		<table class="std-tbl">
			<thead>
				<tr>
					<td></td>
					<td :colspan="numcolsplits" v-for="(ci, index) in columnItems" :key="index" class="bordleft">{{ci.name}}</td>
				</tr>
				<tr>
					<td></td>
					<td v-for="(colname, index) in colXcolsplitNames" :key="index" :class="{bordleft : bordleftindexes.includes(index)}">{{colname}}</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(ri, rowindex) in rowItems" :key="rowindex">
					<td>{{ri.name}}</td>
					<td v-for="(cellconfig, index) in tableCellConfigs[rowindex]" :key="index" :class="{bordleft : bordleftindexes.includes(index)}">
						<ResultCell :filtereddata="filteredData" :obs="cellconfig"></ResultCell>
					</td>
				</tr>

				<tr v-if="showExclusiveRows">
					<td>Listen to multiple stations</td>
					<td v-for="(cellconfig, index) in tableCellConfigs[0]" :key="index" :class="{bordleft : bordleftindexes.includes(index)}" class="norank">
						<ResultCell :filtereddata="filteredData" type="multilisten" :obs="cellconfig"></ResultCell>
					</td>
				</tr>

				<tr v-if="showExclusiveRows">
					<td>Do not listen to daypart</td>
					<td v-for="(cellconfig, index) in tableCellConfigs[0]" :key="index" :class="{bordleft : bordleftindexes.includes(index)}" class="norank">
						<ResultCell :filtereddata="filteredData" type="nolisten" :obs="cellconfig"></ResultCell>
					</td>
				</tr>

				<tr v-if="showTotalRow">
					<td>Mkt Total</td>
					<td v-for="(cellconfig, index) in tableCellConfigs[0]" :key="index" :class="{bordleft : bordleftindexes.includes(index)}" class="norank">
						<ResultCell :filtereddata="filteredData" type="total" :obs="cellconfig"></ResultCell>
					</td>
				</tr>

			</tbody>
		</table>

		<p v-if="store.reportType !== 'reachFrequency'" style="font-size: 11px; color: #888; cursor: pointer; margin: 15px 0;" @click="toggleHighlights">
			<span v-if="store.showGreenHighlights">Clear highlights</span>
			<span v-else>Highlight by rank</span>
		</p>
	</div>
</template>

<script>
import ResultCell from "@/components/result-tables/ResultCell";
import TableHeaderContent from "@/components/result-tables/TableHeaderContent";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ResultTableWithColsplit",
	components: {
		TableHeaderContent,
		ResultCell
	},
	mixins: [globalMixin],
	props: {
		objin: Object,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		returnedData() {
			return this.store.returnedData;
		},
		filteredData() { //filter here to minimise what the resultcell has to do
			let demoIds = [];
			if(this.objin.demos.id) demoIds = [this.objin.demos.id];
			else {
				for(let ob of this.objin.demos) {
					demoIds.push(ob.id);
				}
			}
			let survids = [];
			if(this.objin.surveys.id) survids = [this.objin.surveys.id];
			else {
				for(let ob of this.objin.surveys) {
					survids.push(ob.id);
				}
			}
			let stationids = [];
			if(this.objin.stations.id) stationids = [this.objin.stations.id];
			else {
				for(let ob of this.objin.stations) {
					stationids.push(ob.id);
				}
			}
			let statisticids = [];
			if(this.objin.statistics.id) statisticids = [this.objin.statistics.id];
			else {
				for(let ob of this.objin.statistics) {
					statisticids.push(ob.id);
				}
			}
			return this.store.returnedData.filter(item =>
				demoIds.includes(item.demoid) && stationids.includes(item.stationid) && survids.includes(item.suid) && statisticids.includes(item.statisticid)
			);
		},
		resultMeta() {
			return this.store.resultMeta;
		},
		layout() {
			return this.store.selectedTableLayout;
		},
		columnItems() {
			return this.objin[this.objin.col];
		},
		rowItems() {
			return this.objin[this.objin.row];
		},
		tblItem() {
			return this.objin[this.objin.table];
		},
		colsplititems() {
			return this.objin[this.objin.colsplit];
		},
		colXcolsplitNames() {
			let out = [];
			for(let c=0; c<this.columnItems.length; c++) {
				for(let cs=0; cs<this.colsplititems.length; cs++) {
					let mycsitem = this.colsplititems[cs];
					out.push(mycsitem.name);
				}
			}
			return out;
		},
		bordleftindexes() {
			let b = [0];
			for(let c=1; c<this.columnItems.length; c++) {
				b.push(c * this.colsplititems.length);
			}
			return b;
		},
		tableCellConfigs() {
			let t = []; //rows => cells
			for(let ri=0; ri<this.rowItems.length; ri++) {
				let myrowitem = this.rowItems[ri];
				let myrow = [];
				for(let ci=0; ci<this.columnItems.length; ci++) {
					let mycolitem = this.columnItems[ci];
					for(let cs=0; cs<this.colsplititems.length; cs++) {
						let mycsitem = this.colsplititems[cs];
						myrow.push([mycolitem, myrowitem, mycsitem, this.tblItem]);
					}
				}
				t.push(myrow);
			}
			return t;
		},
		numcolsplits() {
			return this.colsplititems.length;
		},
		showTotalRow() {
			return true;
		},
		showExclusiveRows() {
			if(this.showTotalRow === false) return false;
			if(this.objin.statistics.id) { //single statistic object in this table
				if(this.objin.statistics.isExclusive === 1) return true;
			}
			else {
				for (let i=0; i<this.objin.statistics.length; i++) {
					if(this.objin.statistics[i].isExclusive === 1) return true;
				}
			}
			return false;
		},
	},
	methods: {
		toggleHighlights() {
			if(this.store.showGreenHighlights) {
				this.clearHighlights(); //mixin
				this.store.showGreenHighlights = false;
			}
			else {
				this.store.showGreenHighlights = true;
				this.highlightByCol(); //mixin
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.rtblHolder {
	margin-bottom: 40px;
}
</style>