<template>
	<div class="loader-dots" style="width:100%; background-color: rgba(255,255,255,0.5);">
		<div class="loader-dot loader-dot-grey"></div>
		<div class="loader-dot loader-dot-grey"></div>
		<div class="loader-dot loader-dot-grey"></div>
	</div>
</template>

<script>

export default {
	name: 'ChartLoaderInsert',
	components: {},
	data() {
		return {

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {},
	props: {
		shown: Boolean,
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
