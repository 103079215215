<template>
	<div class="view-holder">

		<p v-if="store.user.id === 1" style="margin-bottom: 10px;">Welcome, {{store.user.email}}</p>

<!--		<NotificationBox>-->
<!--			<p class="bold" style="font-size: 16px;">Welcome to the latest version of Frequency</p>-->
<!--			<br/>-->
<!--			<p>Please choose a report type to run below, or choose to open a saved job from the menu above as usual.</p>-->
<!--		</NotificationBox>-->

		<div style="display: flex; flex-wrap: wrap; gap: 50px; padding: 50px 0;">
			<div class="large-tile-button" @click="setReportType('normal')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-chart-tree"></i>
					</div>
					<p>Standard Report</p>
				</div>
			</div>
			<div class="large-tile-button" @click="setReportType('reachFrequency')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-radio-tower"></i>
					</div>
					<p>R&F Report</p>
				</div>
			</div>

			<div class="large-tile-button" @click="store.chartExplorerOverlayShown = true">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-chart-bar"></i>
					</div>
					<p>Chart Explorer</p>
				</div>
			</div>
		</div>
		<div style="display: flex; flex-wrap: wrap; gap: 50px; padding: 0;">
			<div v-if="store.canSeeSpecial.includes(store.user.id)" class="large-tile-button" @click="setReportType('allQIDs')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-account-question"></i>
					</div>
					<p class="sub">Special</p>
					<p>Question Outputs</p>
				</div>
			</div>
			<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('StnDemoCalcs')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-clock-outline"></i>
					</div>
					<p class="sub">Special</p>
					<p>TSL Crosstabs</p>
				</div>
			</div>
			<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('pctStnCume')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-stove"></i>
					</div>
					<p class="sub">Special</p>
					<p>Pct Cume By Daypart</p>
				</div>
			</div>
			<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('stnCumeSharing')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-share-variant-outline"></i>
					</div>
					<p class="sub">Special</p>
					<p>Audience Sharing by Station Cume</p>
				</div>
			</div>
			<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('stnMostByCumeStn')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-star-outline"></i>
					</div>
					<p class="sub">Special</p>
					<p>Station Most (Overall/ROS) split by Station Cume</p>
				</div>
			</div>
			<div v-if="store.user.agencyUser === 0" class="large-tile-button" @click="setReportType('ebbNFlow')">
				<div class="tile-inside">
					<div class="tile-icon-hold">
						<i class="mdi mdi-coffee-outline"></i>
					</div>
					<p class="sub">Special</p>
					<p>Flow From Breakfast</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
// import NotificationBox from "@/components/NotificationBox";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ReportTypeChooser",
	components: {
		// NotificationBox
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {
		// setReportType(typename) {
		// 	this.setReportType(typename);
		// 	this.store.showGreenHighlights = false;
		// },
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 20px;
}
</style>