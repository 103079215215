<template>
	<div>
		<p style="margin: 15px 0 5px;">
			<span style="font-weight: bold; margin-right: 5px; display: inline-block;">{{ucfirst(groupob.name)}}</span>
			<span v-if="groupob.descrip.length > 0" style="font-size: 11px; color: #888;">{{groupob.descrip}}</span>
		</p>
		<div v-for="st in filteredStatistics" :key="st.id" class="checklist-item">
			<div class="ch-and-title" @click="toggleStatisticInSelection(st)">
				<span class="toggler">
					<i v-if="store.selectionObs.statistics.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label">{{st.name}}</span>
			</div>
			<InfoIcon addclasses="small" :id="'st'+st.id" @click="popoverClicked('st'+st.id)"></InfoIcon>
			<PopoverItem v-if="popoverIdShown === 'st'+st.id" :title="st.name" v-on:close="hidePopovers">
				<template v-slot:content>
					<p>{{groupob.descrip}}</p>
					<p v-html="getStatDescrip(st.descrip)"></p>
				</template>
			</PopoverItem>
<!--			<b-popover :target="'st'+st.id" triggers="click focus" placement="right">-->
<!--				<template #title>-->
<!--					<div style="display: flex; justify-content: space-between; width: 100%;">-->
<!--						{{st.name}}-->
<!--						<span @click="$root.$emit('bv::hide::popover')" style="cursor: pointer"><i class="mdi mdi-close"></i></span>-->
<!--					</div>-->
<!--				</template>-->
<!--				<p>{{groupob.descrip}}</p>-->
<!--				<p v-html="getStatDescrip(st.descrip)"></p>-->
<!--			</b-popover>-->
		</div>
	</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon";
import PopoverItem from "@/components/PopoverItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "StatisticGroup",
	components: {
		PopoverItem,
		InfoIcon
	},
	mixins: [globalMixin],
	props: {
		filtertext: String,
		groupob: Object,
	},
	data: function () {
		return {
			store: store,
			popoverIdShown: null,
		}
	},
	computed: {
		filteredStatistics() {
			let mystats = this.store.statistics.filter(item => this.groupob.name === item.daypartGroup);
			mystats = mystats.filter(this.isStatisticAvailableForSelectedSurveys); //check if this statistic is allowed from the surveys selected
			if(this.filtertext.length === 0) return mystats;
			else { //either already selected or matching text
				return mystats.filter(item => this.store.selectionObs.statistics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filtertext.toLowerCase()));
			}
		},
	},
	methods: {
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		getStatDescrip(keyname) {
			let sn = this.store.statisticDescrips.find(item => item.name === keyname);
			if(sn) {
				return sn.descrip;
			}
			return '';
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>