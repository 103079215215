<template>
	<div class="rtblHolder">
		<div style="margin-bottom: 20px;">
			<p v-for="header in headers" :key="header" v-html="header"></p>
		</div>

		<table class="std-tbl">
			<thead>
				<tr>
					<td></td>
					<td v-for="(ci, index) in columnItems" :key="index" v-html="ci"></td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(ri, index) in rowItems" :key="index" :class="{notshown : index > maxRowsToShow}">
					<td v-for="(rii, index) in ri" :key="index">{{rii}}</td>
				</tr>
			</tbody>
		</table>

		<p v-if="store.reportType !== 'reachFrequency'" style="font-size: 11px; color: #888; cursor: pointer; margin: 15px 0;" @click="toggleHighlights">
			<span v-if="store.showGreenHighlights">Clear highlights</span>
			<span v-else>Highlight by rank</span>
		</p>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ResultTableBasic",
	components: {

	},
	mixins: [globalMixin],
	props: {
		objin: Object,
	},
	data: function () {
		return {
			store: store,
			showAllRowsTogglerRf: true,
			rfmainrowNumber: 12,
		}
	},
	computed: {
		maxRowsToShow() {
			if(this.showAllRows === true) return this.rowItems.length;
			return this.rowItems.length - this.rfmainrowNumber;
		},
		showAllRows() {
			if(this.store.reportType !== 'reachFrequency') return true;
			return this.showAllRowsTogglerRf;
		},
		columnItems() {
			return this.objin.colnames;
		},
		rowItems() {
			return this.objin.rows;
		},
		headers() {
			return this.objin.header;
		},
		returnedData() {
			return this.store.returnedData;
		},
	},
	methods: {
		toggleHighlights() {
			if(this.store.showGreenHighlights) {
				this.clearHighlights(); //mixin
				this.store.showGreenHighlights = false;
			}
			else {
				this.store.showGreenHighlights = true;
				this.highlightByCol(); //mixin
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.rtblHolder {
	margin-bottom: 40px;
}
.notshown {
	display: none;
}
.std-tbl tbody tr:last-of-type.notshown {
	display: table-row;
}
</style>