<template>
	<div>
		<div style="font-size: 12px; text-transform: uppercase; padding: 5px 0; margin-top: 10px; font-weight: bold;" class="">
			<i class="mdi mdi-folder-outline"></i>
			{{folderob.name}}
			<span v-if="folderExpanded" @click="folderExpanded = false" style="cursor:pointer;"><i class="mdi mdi-minus"></i></span>
			<span v-else @click="folderExpanded = true"><i class="mdi mdi-plus" style="cursor:pointer;"></i></span>
		</div>
		<div v-for="stn in filteredCombosInFolder" :key="stn.id" class="checklist-item">
			<div v-if="folderExpanded" class="ch-and-title" @click="$emit('togglecombo', stn)">
				<span class="toggler">
					<i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
			</div>
			<InfoIcon v-if="folderExpanded" v-on:clicked="$emit('showpopover', stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
		</div>
	</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ComboViewFolder",
	components: {
		InfoIcon
	},
	mixins: [globalMixin],
	props: {
		combos: Array,
		folderob: Object,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeComboId: 0,
			folderExpanded: true,
		}
	},
	computed: {
		filteredCombosInFolder() {
			return this.combos.filter(item => item.infolder === this.folderob.id);
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>