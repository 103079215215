<template>
	<div>
		<div v-if="store.selectionObs.markets.length === 0 || store.selectionObs.surveys.length === 0">
			<p>Please choose at least one market and survey first</p>
		</div>
		<div class="view-holder" v-else>
			<p style="line-height: 1.5;">Stations Selected: {{store.selectionObs.stations.length}}</p>
			<p style="line-height: 1.5;">Total Spots: {{totalSpots}}</p>
			<p style="line-height: 1.5;">Number of Weeks (max 4): <input type="number" style="border: 1px solid #CCC;  text-align: center; padding: 5px; width: 60px;" v-model="nweeks"/></p>
			<p style="margin-top: 5px; font-size:12px; color: #888">Total Spots is the sum of all spots on all stations across the schedule. Spot numbers in the sessions below are assumed to be spread across the week (eg 10 spots in Morning means 10 spots total placed in mornings in that station across the week eg 2 per day). All dayparts are Mon-Fri except Weekend.</p>
			<br/>
			<p>Weekly spot schedule</p><br/>

			<div class="fspottable">
				<div class="row1">
					<div class="colsched firstcol" style="color: #EFEFEF;">.</div>
					<div class="colsched" v-for="d in dayparts" :key="d.name" :title="d.descrip">{{d.name}}</div>
				</div>

				<ScheduleWeek v-for="weekid in weekidarr" :key="weekid"
					:weekid="weekid" :pasteinprogress="pasteinprogress"
					v-on:updatejson="updatejson($event, weekid)"
					v-on:pastetonewweek="pasteWeekFromClipboard(weekid)"
				></ScheduleWeek>

			</div>
		</div>
	</div>
</template>

<script>
import ScheduleWeek from "@/components/rf/ScheduleWeek";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Schedule",
	components: {
		ScheduleWeek
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			dayparts: [
				{name: 'Breakfast', descrip: 'Mon-Fri 5:30am to 9:00am'},
				{name: 'Morning', descrip: 'Mon-Fri 9:00am to 12:00md'},
				{name: 'Afternoon', descrip: 'Mon-Fri 12:00md-4:00pm'},
				{name: 'Drive', descrip: 'Mon-Fri 4:00pm-7:00pm'},
				{name: 'Night', descrip: 'Mon-Fri 7:00pm-12:00mn'},
				{name: 'Weekend', descrip: 'Sat-Sun all times'},
			],
			weekidarr: [1,2,3,4],
			pasteinprogress: false,
		}
	},
	computed: {
		nweeks: {
			get() {
				return this.store.nweeksrf;
			},
			set(value) {
				this.store.nweeksrf = parseInt(value);
			}
		},
		rfjsonarr: { //this is [{weekid: 1, obs: ...},]
			get() {
				return this.store.rfjsonarr;
			},
			set(arr) {
				this.store.rfjsonarr = arr;
			}
		},
		reportType() {
			return this.store.reportType;
		},
		totalSpots() {
			return this.getRfTotalSpotsComputed()
		},
	},
	methods: {
		updatejson(obsarr, weekid) { //obsarr will contain objects from a single week
			let arr = this.rfjsonarr.filter(item => item.weekid !== weekid); //remove any existing old ones
			for(let i=0; i<obsarr.length; i++) {
				let myob = obsarr[i];
				arr.push(myob); //push the new ones in
			}
			this.rfjsonarr = arr;
		},
		pasteWeekFromClipboard(updateWeekId) {
			let tempstore = this.store.rfjsonarr.filter(item => item.weekid !== updateWeekId); //remove any existing vals for this week
			for(let j=0; j<this.store.rfweekclipboard.length; j++) {
				let olditem = this.store.rfweekclipboard[j];
					let newitem = {
						station: olditem.station,
						weekid: updateWeekId,
						sessionSpots: olditem.sessionSpots
					};
				tempstore.push(newitem);
			}
			this.pasteinprogress = true;
			this.store.rfjsonarr = tempstore;
			setTimeout(function() {
				this.pasteinprogress = false;
			}.bind(this), 20);
		},
	},
	watch: {
		nweeks() {
			if(this.nweeks < 1) this.nweeks = 1;
			else if(this.nweeks > this.store.nweeksmax) this.nweeks = this.store.nweeksmax;
			this.rfjsonarr = this.rfjsonarr.filter(item => item.weekid <= this.nweeks); //update the json to remove any non-existent weeks
		},
		totalSpots() {
			this.store.rfTotalSpots = this.totalSpots;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}


</style>