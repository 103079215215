<template>
	<div class="loader-overlay">
		<div class="loader-box">
			<img src="@/assets/rrloader.gif" alt="Working" />
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderOverlay",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.loader-box {
	margin-top: 180px;
	padding: 0 100px;
	height: 150px;
	background-color: #FFF;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>