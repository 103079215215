<template>
	<div class="sortable-internal">
		<div>{{title}}</div>
		<div class="remover" @click="$emit('remove')"><i class="mdi mdi-close"></i></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SortableInternal",
	components: {},
	mixins: [globalMixin],
	props: {
		type: String,
		objid: Number,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		obj() {
			if(this.type === 'station') {
				if (this.objid > 10000) return this.store.stationcombos.find(item => item.id === this.objid);
				else return this.store.stations.find(item => item.id === this.objid);
			}
			else if(this.type === 'demographic' || this.type === 'demo') {
				return this.store.demos.find(item => item.id === this.objid);
			}
			else if(this.type === 'statistic') {
				return this.store.statistics.find(item => item.id === this.objid);
			}
			return false;
		},
		title() {
			if(this.obj && this.obj.name) {
				if (this.type === 'station') return this.obj.name;
				else if (this.type === 'demographic' || this.type === 'demo') return this.obj.name;
				else if (this.type === 'statistic') return this.obj.name;
			}
			return '';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.sortable-internal {
	padding: 4px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

</style>