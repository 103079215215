<template>
	<div class="loader-overlay">
		<div class="chart-explorer-view">
			<div class="market-summary">
				<div class="popup-topbar">
					<span class="boldme">Frequency Chart Explorer</span>
					<span class="closer" @click="store.chartExplorerOverlayShown = false">
						<i class="mdi mdi-close"></i>
					</span>
				</div>
				<div class="popup-insides">
					<div style="display: flex; justify-content: flex-start; gap: 15px; margin: 0px 0 20px;">
						<div>
							<select class="select-styled" v-model="chosenMarket">
								<option v-for="opt in availMarkets" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div>
							<select class="select-styled" v-model="chosenDemographic">
								<option v-for="opt in availDemographics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div>
							<select class="select-styled" v-model="chosenStatistic">
								<option v-for="opt in availStatistics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
							</select>
						</div>
						<div><span class="link-btn" style="padding: 13px 20px 14px; border-radius: 5px" @click="getChartData">Fetch</span></div>
						<div style="margin-top: 10px; display: flex; align-items: center; justify-content: center">
							<input id="chtrln" type="checkbox" v-model="useLineChart" />
							<label for="chtrln" style="font-size: 12px; margin: 1px 0 0 5px; display: inline-block">Trend Line chart</label>
						</div>
					</div>

					<ChartHolderDash :charttitle="chartTitle" :source="chartSource" >
						<template v-slot:default>
							<p v-if="allzeroes">There's either no Xtra CRA Gold Standard survey data for this market, or the demographic/daypart selection has no data (perhaps due to sample size)</p>
							<ApexFreqLineTrendMulti v-if="useLineChart && !allzeroes" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
							<ApexFreqBar v-if="useLineChart === false && !allzeroes" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqBar>
						</template>
					</ChartHolderDash>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApexFreqBar from "@/components/charts/ApexFreqBar";
import ApexFreqLineTrendMulti from "@/components/charts/ApexFreqLineTrendMulti";
import ChartHolderDash from "@/components/charts/ChartHolderDash";
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "FreqChartExplorer",
	components: {
		ApexFreqBar,
		ApexFreqLineTrendMulti,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingCharts: false,
			defaultColorCount: 0,
			collatedMarketData: [],
			useLineChart: false,

			chartdata: [],
			chartcolors: [],

			allowedDemoIds: [48,50,532,118,1323,152,2316,1322,266,239,3131,159,272,255,535,803,117,373,151,49,1321,265,238,3130,158,271,64,65,374,78,1326,263,66,458,3129,68,181],
			allowedStatisticIds: [11,13,15,17,19,21,23,2,4,5,6,7,8,10],

			chosenMarket: 0,
			chosenDemographic: 48,
			chosenStatistic: 2,

			surveynames: [],
			allzeroes: [],
		}
	},
	computed: {
		availMarkets() {
			return this.store.markets.filter(item => item.explicitFullAccess === 1 && item.surveyed === 1 && item.hidden !== 1);
		},
		availDemographics() {
			return this.store.demos.filter(item => this.allowedDemoIds.includes(item.id));
		},
		availStatistics() {
			return this.store.statistics.filter(item => this.allowedStatisticIds.includes(item.id));
		},
		appliedStations() {
			let stns = this.store.stations.filter(item => item.mktid === this.chosenMarket && item.band !== 'dab' && item.outsidemkt !== 1 && item.community === 0 && ((item.isCommercial === 1 && item.band.length > 0) || item.subnetown === 'abc_loc' || item.subnetown === 'abc_j'));
			let stationids = [];
			for(let s=0; s<stns.length; s++) {
				stationids.push(stns[s].id);
			}
			return stationids;
		},
		chartTitle() {
			let dem = this.store.demos.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			if(dem && mkt && stt) {
				return mkt.name + ", " + dem.name + ", " + stt.name;
			}
			return '';
		},
		chartSource() {
			let dem = this.store.demos.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			if(dem && mkt && stt) {
				return "Xtra Insights " + mkt.name + " surveys (" + this.surveynames.join(", ") + "), " + dem.name + ", " + stt.name;
			}
			return '';
		},
		cdatalength() {
			return this.chartdata.length;
		},
	},
	methods: {
		getChartData() { //for cache lookup
			this.fetchingCharts = true;
			let self = this;
			let lookupdets = {
				userob: this.store.user,
				markets: [this.chosenMarket],
				// surveys: this.lookupDetails.surveys,
				stations: this.appliedStations,
				demoids: [this.chosenDemographic],
				statisticids: [this.chosenStatistic],
			};
			let targ = self.store.apiroot + "arnstats/get-cache-data";
			axios.post(targ, JSON.stringify(lookupdets)
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.data && ret.data.length > 0) { //normal report type
					self.collatedMarketData = ret.data;
					self.prepareChartData();
				}
				else { //no data returned from cache
					self.allzeroes = true;
				}
				if (ret.reterror) {
					self.store.reterrormsg = ret.reterror;
				}
				self.fetchingCharts = false;
			}).catch(function() {
				self.fetchingCharts = false;
			});
		},
		prepareChartData() {
			let cdata = [];
			let colors = [];
			this.defaultColorCount = 0;
			this.surveynames = [];
			let allzeroes = true; //to help tell us if no data has been returned (all zeroes from cache)
			if (this.chosenDemographic > 0 && this.chosenStatistic > 0) {
				for (let s = 0; s < this.appliedStations.length; s++) {
					let cseries = {};
					let stid = this.appliedStations[s];
					let stnob = this.store.stations.find(item => item.id === stid);
					cseries.name = stnob.name;
					cseries.data = [];
					colors.push(this.getColor(stnob));
					let ob = this.collatedMarketData.find(item => item.demoid === this.chosenDemographic && item.statisticid === this.chosenStatistic && item.stnid === stid);
					for(let i=ob.jsob.length-1; i>=0; i--) { //these are newest to oldest
						let cell = ob.jsob[i];
						let suid = cell.suid;
						let suob = this.store.surveys.find(item => item.id === suid);
						cseries.data.push({
							x: suob.name,
							y: cell.val,
						});
						if(cell.val > 0) {
							allzeroes = false;
						}
						if(!this.surveynames.includes(suob.name)) {
							this.surveynames.push(suob.name);
						}
					}
					cdata.push(cseries);
				}
				this.chartdata = cdata;
				this.chartcolors = colors;
				this.allzeroes = allzeroes;
			}
		},
		getColor(stnob) {
			let l = this.store.netcolors.find(item => item.key === stnob.repsubnet);
			if(l) return l.col;
			l = this.store.netcolors.find(item => item.key === stnob.subnetown);
			if(l) return l.col;
			let col = this.store.defaultColors[this.defaultColorCount];
			this.defaultColorCount++;
			return col;
		},
	},
	watch: {
		chosenMarket() { this.getChartData(); },
		chosenStatistic() { this.getChartData(); },
		chosenDemographic() { this.getChartData(); },
	},
	mounted() {
		if(this.chosenMarket === 0) {
			this.chosenMarket = this.availMarkets[0].id;
		}
		if(this.chosenMarket > 0 && this.appliedStations.length > 0 && this.chosenStatistic > 0 && this.chosenDemographic > 0) {
			this.getChartData();
		}
	}
}
</script>

<style scoped>
.chart-explorer-view {
	width: 80%;
	height: 800px;
	max-height: 90%;
	position: fixed;
	top: 5%;
	left: 10%;
}
.market-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.content-block p {
	line-height: 1.6;
}
.select-styled {
	padding: 9px 7px;
	border: 1px solid #BBB;
	border-radius: 5px;
	-webkit-appearance: auto;
	-moz-appearance: auto;
	font-size: 16px;
	width: 100%;
	display: inline-block;
}
@media screen and (max-width: 1350px) {
	.chart-explorer-view {
		width: 90%;
		left: 5%;
	}
}
</style>