<template>
	<div class="view-holder">
		<p class="subheading">{{reportTitle}}</p>
		<div class="filterbox-hold">
			<select v-model="store.marketViewListChooser" style="padding: 5px; margin-right: 10px; border: 1px solid #CCC;">
				<option value="all">Show All Markets</option>
				<option value="surveyed">Show Surveyed Markets</option>
				<option value="fullaccess">Show Markets With Data Access</option>
			</select>
			<input type="text" placeholder="Filter market by name..." v-model="filterText" class="filterbox" />
		</div>
		<div class="checkbox-list-hold">
			<MarketsTable :filtertext="filterText" :inrows="filteredMarkets" v-on:showsum="showMarketSummary($event)"></MarketsTable>
		</div>

		<div v-if="showSummaryView" class="loader-overlay">
			<div class="market-summary-view">
				<MarketSummary :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
			</div>
		</div>
	</div>
</template>

<script>
import MarketSummary from "@/components/views/MarketSummary";
import MarketsTable from "@/components/MarketsTable";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Markets",
	components: {
		MarketsTable,
		MarketSummary,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			fields: [
				'selected', //virtual column
				{key: 'name', label: 'Market Name', sortable: true},
				{key: 'state', label: 'State', sortable: true},
				{key: 'indpop', label: '10+ Pop', sortable: true, thClass: 'text-right'},
				'ownership', //virtual column
				'representation', //virtual column
				{key: 'tvAggId', label: 'TV Agg Mkt', sortable: true},
				{key: 'surveyed', label: 'Surveyed', sortable: true},
				// {key: 'craPDFurl', label: 'CRA PDF', sortable: false},
			],
			rowClass: 'trow',

			summaryMarketOb: null,
			showSummaryView: false,
		}
	},
	computed: {
		listviewchooser() {
			return this.store.marketViewListChooser;
		},
		reportType() {
			return this.store.reportType;
		},
		reportTitle() {
			if(this.reportType === 'reachFrequency') return "R&F Report";
			return "Standard Report";
		},
		filteredMarkets() {
			let temp = [];
			if(this.listviewchooser === 'all') temp = this.store.markets;
			else if(this.listviewchooser === 'surveyed') temp = this.store.markets.filter(item => item.surveyed === 1);
			else if(this.listviewchooser === 'fullaccess') temp = this.store.markets.filter(item => item.explicitFullAccess === 1);
			if(this.filterText.length > 0) { //either already selected or matching text
				return temp.filter(item => this.store.selectionObs.markets.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return temp;
		},
		shortcutMarketTrigger() {
			return this.store.shortcutmarketTrigger;
		},
	},
	methods: {
		showMarketSummary(mktob) {
			this.summaryMarketOb = mktob;
			this.showSummaryView = true;
		},
		shortcutMarkets(type, statename) {
			let sels = [];
			if(type === 'state') {
				if(statename === 'all') {
					if(this.store.selectionObs.markets.length > 0) {
						this.store.selectionObs.markets = [];
					}
					else {
						for(let i=0; i<this.filteredMarkets.length; i++) {
							let myob = this.filteredMarkets[i];
							if(!sels.some(item => item.id === myob.id)) {
								sels.push(myob);
							}
						}
						this.store.selectionObs.markets = sels;
					}
				}
				else {
					if(this.store.selectionObs.markets.length > 0) {
						this.store.selectionObs.markets = [];
					}
					else {
						let myobs = this.filteredMarkets.filter(item => item.state === statename);
						for(let i=0; i<myobs.length; i++) {
							let myob = myobs[i];
							if(!sels.some(item => item.id === myob.id)) {
								sels.push(myob);
							}
						}
						this.store.selectionObs.markets = sels;
					}
				}
			}
			else if(type === 'tvagg') {
				let myobs = this.filteredMarkets.filter(item => item.tvAggId === statename);
				for(let i=0; i<myobs.length; i++) {
					let myob = myobs[i];
					if(!sels.some(item => item.id === myob.id)) {
						sels.push(myob);
					}
				}
				this.store.selectionObs.markets = sels;
			}
			else if(type === 'owner') {
				let myobs = this.filteredMarkets.filter(item => item.ownership.owns.includes(statename));
				for(let i=0; i<myobs.length; i++) {
					let myob = myobs[i];
					if(!sels.some(item => item.id === myob.id)) {
						sels.push(myob);
					}
				}
				this.store.selectionObs.markets = sels;
			}
			else if(type === 'rep') {
				let myobs = this.filteredMarkets.filter(item => item.ownership.reps.includes(statename));
				for(let i=0; i<myobs.length; i++) {
					let myob = myobs[i];
					if(!sels.some(item => item.id === myob.id)) {
						sels.push(myob);
					}
				}
				this.store.selectionObs.markets = sels;
			}
		},
	},
	watch: {
		shortcutMarketTrigger() {
			let ar = this.store.shortcutmarketTrigger
			this.shortcutMarkets(ar[0], ar[1])
		},
	},
	mounted() {

	}
}
</script>
<style>
table.market-table tr {
	transition: background-color 0.2s ease-in-out;
}
table.market-table tbody tr:hover {
	background-color: #EFEFEF;
}
table.market-table td {
	vertical-align: middle;
	padding: 2px 15px;
}
th.text-right {
	text-align: right !important;
}
</style>
<style scoped>
.view-holder {
	margin: 0 20px;
}
.market-table {
	max-width: 1200px;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.mktlabel {
	cursor: pointer;
}
.mktlabel:hover {
	text-decoration: underline;
}
</style>