<template>
	<div class="menubar">
		<div class="menu-insides">
			<router-link to="/">
				<img src="@/assets/frequency-logo.png" alt="Logo" class="top-logo" title="Click to start new report" @click="unsetReportType" />
			</router-link>
			<div class="menu-items">
				<TopMenuGroup label="System" :hasdropdown="true">
					<TopMenuItem label="New Report" @click="unsetReportType"></TopMenuItem>
					<TopMenuItem label="Colour Highlights" @click="toggleHighlights"></TopMenuItem>
					<TopMenuItem label="Help & Glossary" @click="goToHelp"></TopMenuItem>
					<TopMenuItem label="Tutorial Videos" @click="goToTutorialVid"></TopMenuItem>
					<TopMenuItem label="Log Out" @click="logout"></TopMenuItem>
				</TopMenuGroup>
				<TopMenuGroup label="Jobs" :hasdropdown="true">
					<TopMenuItem label="New Job" @click="newJob"></TopMenuItem>
					<TopMenuItem label="Open & Manage" @click="store.jobManageOverlayShown = true"></TopMenuItem>
					<TopMenuItem label="Save" @click="saveJob"></TopMenuItem>
					<TopMenuItem label="Copy To New" @click="saveToNewJob"></TopMenuItem>
					<TopMenuItem label="Batches" @click="store.batchManageOverlayShown = true"></TopMenuItem>
				</TopMenuGroup>
				<TopMenuGroup label="Demographics" :hasdropdown="true">
					<TopMenuItem label="New Standard Demo" @click="createNewDemo('thisuser',0)"></TopMenuItem>
					<TopMenuItem v-if="store.canSeeSpecial.includes(store.user.id)" @click="createNewDemo('thisuser',1)" label="New Demo (this survey)"></TopMenuItem>
					<TopMenuItem label="Manage Demos" @click="store.demosManageOverlayShown = true"></TopMenuItem>
				</TopMenuGroup>
				<TopMenuGroup label="Combos" :hasdropdown="true">
					<TopMenuItem label="Create Combo" @click="createNewCombo"></TopMenuItem>
					<TopMenuItem label="Edit Existing Combo" @click="viewCombosList"></TopMenuItem>
				</TopMenuGroup>
				<TopMenuGroup label="Special" :hasdropdown="true">
					<TopMenuItem v-if="store.canSeeSpecial.includes(store.user.id)" label="Standard Demo (All Users)" @click="createNewDemo('allusers',0)"></TopMenuItem>
					<TopMenuItem v-if="store.canSeeSpecial.includes(store.user.id)" label="Demo This Surv (All Users)" @click="createNewDemo('allusers',1)"></TopMenuItem>
					<TopMenuItem label="Weighting Toggle" @click="toggleWeightingFlag"></TopMenuItem>
					<TopMenuItem label="Raw Number Toggle" @click="toggleRawNumFlag"></TopMenuItem>
					<TopMenuItem v-if="store.adminuids.includes(store.user.id)" @click="openUserAdmin" label="User Admin"></TopMenuItem>
					<TopMenuItem @click="toggleRunAsHighlightsFlag" label="Highlights Run"></TopMenuItem>
				</TopMenuGroup>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import TopMenuGroup from "@/components/TopMenuGroup";
import TopMenuItem from "@/components/TopMenuItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "MenuBar",
	components: {
		TopMenuItem,
		TopMenuGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			openMenu: false,
			jobIsSaving: false,
		}
	},
	computed: {},
	methods: {
		logout() {
			let c = window.confirm("Are you sure you wish to log out?  This will mean you need to add your password in at the login screen next time.  Unless you are on a shared or public computer you can just close the browser window, and we'll remember you.");
			if(c !== false) {
				let newusr = {
					id: 0,
					email: '',
					token: '',
					isadmin: null,
				};
				this.clearLocalStorage(); //unset storage for useremail & usertoken
				this.setUser(newusr);
				this.$router.push('/login?loggedout=1');
			}
		},
		createNewDemo(globalType, specificSurvId) {
			let userid = this.store.user.id; //globalType === 'thisuser'
			if(globalType === 'allusers') {
				userid = 0;
			}
			let survid = 0;
			if(specificSurvId === 1) {
				let mysurvid = this.store.selectionObs.surveys[0].id; //this will be the SUID (but we need to save the WL dataloc)
				if(!mysurvid) {
					alert("Please select a survey");
					return false;
				}
				let mysurvob = this.store.surveys.find(item => item.id === mysurvid);
				survid = mysurvob.dataloc;
			}
			this.store.demoNewData = {userid: userid, survid: survid};
			this.store.demoNewOverlayShown = true;
		},
		createNewCombo() {
			this.store.comboView = 'edit';
			this.store.combosManageOverlayShown = true;
		},
		viewCombosList() {
			this.store.comboView = 'main';
			this.store.combosManageOverlayShown = true;
		},
		goToHelp() {
			window.open("https://kb.regionalratings.com.au/frequency/","_blank");
		},
		goToTutorialVid() {
			window.open("https://kb.regionalratings.com.au/frequency/getting-started/tutorial-videos/","_blank");
		},
		toggleHighlights() {
			if(this.store.showGreenHighlights) {
				this.clearHighlights(); //mixin
				this.store.showGreenHighlights = false;
			}
			else {
				this.store.showGreenHighlights = true;
				this.highlightByCol(); //mixin
			}
		},
		openUserAdmin() {
			let targ = this.store.urlroot + "central/frequency-user-manager?vue=1&rrtok="+this.store.user.token+"&rruid="+this.store.user.id;
			window.open(targ, '_blank');
		},
		saveToNewJob() {
			let jname = prompt("Please enter a name for your job");
			if(jname.length > 0 && jname != "" && jname != null && jname !== 'null') {
				this.store.jobid = 0;
				this.store.jobname = jname;
				this.saveJob();
			}
		},
		saveJob() {
			if(this.jobIsSaving === false) {
				this.jobIsSaving = true;
				let self = this;
				let tbllayout = this.store.selectedTableLayout.table+"|"+this.store.selectedTableLayout.row+"|"+this.store.selectedTableLayout.col;
				if(this.store.selectedTableLayout.colsplit.length > 0) {
					tbllayout += "|"+this.store.selectedTableLayout.colsplit;
				}
				let jobname = self.store.jobname;
				if(this.store.jobid === 0 && jobname.length === 0) {
					jobname = prompt("Please enter a name for your job");
					if(jobname.length === 0 || jobname === "" || jobname == null || jobname === 'null') {
						return false;
					}
				}
				axios.post(
					self.store.apiroot + "job-save",
					JSON.stringify({
						userob: self.store.user,
						jid: self.store.jobid,
						jobname: jobname,
						survs: self.joinObjectIds(self.store.selectionObs.surveys),
						markets: self.joinObjectIds(self.store.selectionObs.markets),
						stations: self.joinObjectIds(self.store.selectionObs.stations),
						demos: self.joinObjectIds(self.store.selectionObs.demos),
						statistics: self.joinObjectIds(self.store.selectionObs.statistics),
						tableLayout: tbllayout,
						survOldestLeft: self.store.survOldestLeft,
						reportType: self.store.reportType,
						rfjson: JSON.stringify(self.store.rfjsonarr),
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) { //handle returned objects for lists
						self.showKalert(ret.resp)
						if(ret.resp.jobid > 0) {
							self.store.jobid = ret.resp.jobid;
						}
					}
					self.jobIsSaving = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style>
/*.menu-list .nav-link {*/
/*	padding: 10px 20px 9px;*/
/*	color: #FFF !important;*/
/*}*/
/*.menu-list .nav-link:hover {*/
/*	background-color: #454545;*/
/*}*/
/*.dropdown-item {*/
/*	padding: 6px 25px 7px;*/
/*	color: #626262;*/
/*	font-size: 13px;*/
/*	font-family: Roboto;*/
/*	font-weight: normal;*/
/*	transition: all 0.15s linear;*/
/*}*/
/*.dropdown-item:active {*/
/*	color: #626262;*/
/*	text-decoration: none;*/
/*	background-color: #626262;*/
/*}*/
</style>

<style scoped>
.menubar {
	background: url('~@/assets/frequency-header-background.jpg') top right no-repeat #111;
	margin: 0;
	color: #FFF;
	font-size: 12px;
	font-family: 'Roboto';
	font-weight: bold;
	top: 0;
	z-index: 997;
	position: fixed;
	width: 100%;
	height: 40px;
}
.menu-insides {
	display: flex;
	height: 100%;
	align-content: center;
}
.top-logo {
	height: 40px;
	width: 160px;
	cursor: pointer;
}
.menu-items {
	margin-left: 20px;
	color: #FFF;
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
}
.menu-list {
	list-style-type: none;
	margin: 0;
	display: flex;
}

</style>