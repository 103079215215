<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Station Combos List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter station combos..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.combosManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" v-if="comboview === 'main'">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<ComboManageGroup :combos="filteredCombos" v-on:editcombo="editCombo($event)" v-on:deletecombo="delCombo($event)"></ComboManageGroup>

<!--					<div v-for="c in filteredCombos" :key="c.id" class="jli">-->
<!--						<div>-->
<!--							<span class="jname">{{c.name}}</span>-->
<!--							<span v-if="store.user.isadmin === 1" class="jup"> (UID: {{c.userid}})</span>-->
<!--						</div>-->
<!--						<div class="" style="display: flex; justify-content: space-between; width: 100px;">-->
<!--							<span class="jop" @click="editCombo(c)" title="Edit combo">EDIT</span>-->
<!--							<span class="jdel" @click="delCombo(c.id)" title="Delete combo">DELETE</span>-->
<!--						</div>-->
<!--					</div>-->

				</div>
			</div>

			<div class="popup-insides" style="text-align: left;" v-if="comboview === 'edit'">
				<div style="display: flex; justify-content: space-between; padding: 0 0 20px 0; border-bottom: 1px solid #CCC;">
					<div>
						<p style="font-weight: bold; margin-bottom: 5px;">Combo Name </p>
						<input type="text" v-model="editComboName" style="width: 300px; padding: 5px;" />
					</div>
					<div v-if="store.user.id === 1" style="">
						<p style="font-weight: bold; margin-bottom: 5px;">Owner </p>
						<select v-model="chosenOwnerId" style="padding: 5px;">
							<option value="1">AL User</option>
							<option value="0">All/Global</option>
						</select>
					</div>
					<div style="">
						<p style="font-weight: bold; margin-bottom: 5px;">Market </p>
						<select v-model="chosenMarketId" style="padding: 5px;">
							<option value="0">Multi Market</option>
							<option v-for="m in store.markets" :key="m.id" :value="m.id">{{m.name}} {{m.state}}</option>
						</select>
					</div>
				</div>
				<div v-if="editComboQstring.length === 0" style="height: calc(80% - 0px); overflow-y: hidden; display: flex; justify-content: space-between; padding: 0 0 20px 0; border-bottom: 1px solid #CCC;">
					<div class="combo-left" style="padding: 10px 0; width: 49%; overflow-y: auto;">
						<div v-for="m in availMarkets" :key="m.id">
							<p @click="toggleExpandedMarket(m.id)" class="mktname">{{m.name}} {{m.state}}
								<span v-if="expandedMktIds.includes(m.id)"><i class="mdi mdi-minus"></i></span>
								<span v-else><i class="mdi mdi-plus"></i></span>
							</p>
							<p v-for="s in store.stations.filter(item => item.mktid === m.id)" :key="s.id"
								class="stnname" @click="addStationToCombo(s.id)"
								:class="{active : editStationIds.includes(s.id), hidden : !expandedMktIds.includes(m.id)}"
							>
								{{s.name}}
							</p>
						</div>
					</div>
					<div class="combo-right" style="padding: 10px 0; width: 49%; overflow-y: auto;">
						<p v-if="editComboStnObs.length > 0" @click="editComboStnObs = []" style="font-size: 10px; cursor: pointer; margin-bottom: 10px;">Clear All</p>
						<div v-for="stn in editComboStnObs" :key="stn.id" class="stn-item">
							<div>{{stn.name}} ({{getMarketFromStation(stn)}})</div>
							<div class="remover" @click="removeStationFromCombo(stn.id)"><i class="mdi mdi-close"></i></div>
						</div>
					</div>
				</div>
				<div v-if="editComboQstring.length === 0" style="padding: 20px 0;">
					<span @click="saveCombo" class="primary-button" style="margin-right: 10px;">Save</span>
					<span @click="store.comboView = 'main'" class="primary-button cancel-button">Cancel</span>
				</div>
				<div v-if="editComboQstring.length > 0" style="padding: 20px 0;">
					<p>This combo cannot be edited as it uses a system query dynamically generated station list.</p>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import ComboManageGroup from "@/components/combos/ComboManageGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "CombosManage",
	components: {
		ComboManageGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			jobslist: [],
			filterText: '',

			editComboId: 0,
			editComboName: '',
			editComboStnObs: [],
			chosenOwnerId: null,
			chosenMarketId: 0,

			editComboQstring: '',

			expandedMktIds: [],
		}
	},
	computed: {
		availMarkets() {
			if(this.chosenMarketId > 0) {
				return this.store.markets.filter(item => item.id === this.chosenMarketId);
			}
			return this.store.markets;
		},
		filteredCombos() {
			let combos = this.store.stationcombos.filter(item => item.userid === this.store.user.id);
			// if(this.store.user.isadmin === 1) {
			// 	combos = this.store.stationcombos;
			// }
			if(this.filterText.length > 0) {
				return combos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return combos;
		},
		comboview() {
			return this.store.comboView;
		},
		ownerId() {
			if(this.store.user.id === 1 && this.chosenOwnerId) {
				return this.chosenOwnerId;
			}
			return this.store.user.id;
		},
		editStationIds() {
			let c = [];
			for(let i=0; i<this.editComboStnObs.length; i++) {
				c.push(this.editComboStnObs[i].id);
			}
			return c;
		},
	},
	methods: {
		editCombo(cob) {
			this.editComboId = cob.id;
			this.editComboName = cob.name;
			let stnobs = [];
			for(let i=0; i<cob.stationIDsArr.length; i++) {
				stnobs.push(this.store.stations.find(item => item.id === cob.stationIDsArr[i]));
			}
			this.editComboStnObs = stnobs;
			this.chosenOwnerId = cob.userid;
			this.chosenMarketId = cob.mktid;
			this.editComboQstring = cob.qstring;
			this.store.comboView = 'edit';
		},
		saveCombo() {
			if(this.editComboName.length === 0) {
				alert('Please enter a name for your combo');
				return false;
			}
			if(this.editComboStnObs.length === 0) {
				alert('Please choose some stations');
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			axios.post(
				self.store.apiroot + "stationcombo-save",
				JSON.stringify({
					userob: self.store.user,
					cid: self.editComboId,
					ownerid: self.ownerId,
					comboname: self.editComboName,
					mktid: self.chosenMarketId,
					stnids: self.editStationIds.join('|'),
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
				}
				if(ret.stationcombos) {
					self.store.comboView = 'main';
					self.store.stationcombos = ret.stationcombos; //update the combos list with the returned items
				}
				self.store.showLoader = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		getMarketFromStation(st) {
			let mkt = this.store.markets.find(item => item.id === st.mktid);
			if(mkt) return mkt.name + " " + mkt.state;
			return '';
		},
		addStationToCombo(stid) {
			if(!this.editStationIds.includes(stid)) { //add it to the combo
				this.editComboStnObs.push(this.store.stations.find(item => item.id === stid));
			}
			else { //remove it
				this.editComboStnObs = this.editComboStnObs.filter(item => item.id !== stid);
			}
		},
		removeStationFromCombo(stid) {
			this.editComboStnObs = this.editComboStnObs.filter(item => item.id !== stid);
		},
		toggleExpandedMarket(mid) {
			if(this.expandedMktIds.includes(mid)) {
				let x = this.expandedMktIds.filter(item => item !== mid);
				this.expandedMktIds = x;
			}
			else {
				this.expandedMktIds.push(mid);
			}
		},
		createNewCombo() {
			this.editComboId = 0;
			this.editComboName = '';
			this.editComboQstring = '';
			this.comboview = 'edit';
		},
		delCombo(cid) {
			let c = window.confirm("Are you sure you wish to delete this combo?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "stationcombo-delete",
					JSON.stringify({
						userob: self.store.user,
						cid: cid,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.delcid) {
						let tc = self.store.stationcombos.filter(item => item.id !== ret.delcid);
						self.store.stationcombos = tc;
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {
		chosenMarketId() {
			if(!this.expandedMktIds.includes(this.chosenMarketId)) {
				this.expandedMktIds.push(this.chosenMarketId);
			}
		},
	},
	mounted() {
		if(this.store.user.id === 1) {
			this.chosenOwnerId = 1;
		}
	}
}
</script>

<style scoped>
.mktname {
	font-size: 13px;
	font-weight: bold;
	cursor: pointer;
	margin-bottom: 5px;
}
.stnname {
	cursor: pointer;
	font-size: 13px;
	margin-left: 15px;
}
.stnname.active {
	color: #DF533C;
}
.stnname.hidden {
	display: none;
}
.stn-item {
	padding: 4px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #E5E7E9;
	border-radius: 2px;
	background-color: #E6E6E6;
	background-image: linear-gradient(to bottom, #FDFDFD 0px, #E6E6E6 100%);
	transition: background 0.3s linear;
	margin-bottom: 5px;
}

</style>