<template>
	<div>
		<div style="font-size: 12px; text-transform: uppercase; padding: 5px 0; margin-top: 10px; font-weight: bold;" class="">
			<i class="mdi mdi-folder-outline"></i>
			{{folderob.name}}
			<span v-if="folderExpanded" @click="folderExpanded = false" style="cursor:pointer;"><i class="mdi mdi-minus"></i></span>
			<span v-else @click="folderExpanded = true"><i class="mdi mdi-plus" style="cursor:pointer;"></i></span>
		</div>
		<div v-for="dem in filteredDemosInFolder" :key="dem.id" class="checklist-item">
			<div v-if="folderExpanded" class="ch-and-title" @click="$emit('toggledemo', dem)">
				<span class="toggler">
					<i v-if="store.selectionObs.demos.some(item => item.id === dem.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label" :id="'dem'+dem.id">{{dem.name}}</span>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DemoViewFolder",
	components: {},
	mixins: [globalMixin],
	props: {
		demos: Array,
		folderob: Object,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeDemoId: 0,
			folderExpanded: true,
		}
	},
	computed: {
		filteredDemosInFolder() {
			return this.demos.filter(item => item.infolder === this.folderob.id);
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>