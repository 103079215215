<template>
	<div class="market-summary">
		<div class="popup-topbar">
			<span class="boldme">{{mktob.name}}, {{mktob.state}}</span>
			<span class="closer" @click="$emit('close')">
				<i class="mdi mdi-close"></i>
			</span>
		</div>
		<div class="popup-insides">
			<br/>
			<div class="top-content">
				<div class="half content-block">
					<p class="heading">{{mktob.name}}, {{mktob.state}}</p>

					<p v-if="mktob.surveyed === 1 && !gfkmktids.includes(mktob.id) && latestSurv"><span class="bold">Latest Survey: </span>{{latestSurv.name}} ({{latestSurv.relDateArr.monthnice}} {{latestSurv.relDateArr.year}})</p>
					<p v-else-if="gfkmktids.includes(mktob.id)"><span class="bold">This market is surveyed by GFK</span></p>
					<p v-else class="bold">Not surveyed</p>

					<p><span class="bold">10+ Population: </span>{{numdisplay(mktob.indpop)}}</p>
					<p><span class="bold">TV Agg Mkt: </span>{{getTVAggMktName(mktob.tvAggId)}}</p>
					<p><a target="_blank" :href="mktob.craPDFurl">CRA Market Summary</a></p>

					<br/>
					<p class="boldme">Owners</p>
					<div>
						<OwnershipLogo v-for="o in mktob.ownership.owns" :key="o" type="own" :str="o" wid="100px" ht="auto"></OwnershipLogo>
					</div>

					<br/>
					<p class="boldme">Commercial Stations</p>
					<div class="station-cards" style="margin-top: 10px; font-size: 15px;">
						<div v-for="stn in comStations" :key="stn.id" class="station-card">
							<div style="height: 64px;">
								<p class="bold">{{stn.name}} <span v-if="stn.band.length > 0">({{stn.band.toUpperCase()}})</span></p>
							</div>
							<div class="stnlogohold" style="height: 120px; border-bottom: 1px solid #DDD;">
								<StationLogo :stnob="stn"></StationLogo>
							</div>

							<div style="padding: 10px 0; border-bottom: 1px solid #DDD;">
								<OwnershipLogo type="own" :str="stn.netgroupown" wid="80px" ht="auto"></OwnershipLogo>
							</div>

							<div style="padding: 10px 0 0; display: flex; align-items: center; justify-content: center;">
								<OwnershipLogo v-if="stn.repnetgroup.length > 0" type="reps" :str="stn.repnetgroup"></OwnershipLogo>
<!--								<OwnershipLogo v-if="stn.repsubnet.length > 0" type="reps" :str="stn.repsubnet"></OwnershipLogo>-->
							</div>

						</div>
					</div>

				</div>

				<div class="half content-block map-holder" style="">
					<img :src="mapUrl" alt="Market map" style="max-width: 500px; object-fit: contain;" />
				</div>
			</div>

			<div v-if="mktob.surveyed === 1 && !gfkmktids.includes(mktob.id) && latestSurv" style="margin-top: 40px;">
				<p style="font-size: 12px;">Charts shown below utilise publicly released/available data.
					<span v-if="userCanViewFull">To view custom demographics and dayparts, use a Frequency Standard Report.</span>
					<span v-else>Your account/network does not have access to full survey data for this market.</span>
				</p>
				<div style="margin-top: 10px; display: flex; align-items: center; justify-content: center">
					<input id="chtrln" type="checkbox" v-model="useLineChart" />
					<label for="chtrln" style="font-size: 12px; margin: 1px 0 0 5px; display: inline-block">Trend Line chart</label>
				</div>
			</div>

			<div v-if="mktob.surveyed === 1 && !gfkmktids.includes(mktob.id) && latestSurv" class="chart-rows">
				<ChartHolderDash charttitle="10+ Station Listened To Most" source="Xtra Insights: 10+ All People, Station Listened To Most, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10sltm" :chartcolors="chartcolors10sltm" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10sltm" :chartcolors="chartcolors10sltm" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="10+ Cume 00s" source="Xtra Insights: 10+ All People, Cume 00s, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10cume" :chartcolors="chartcolors10cume" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10cume" :chartcolors="chartcolors10cume" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === 1 && !gfkmktids.includes(mktob.id) && latestSurv" class="chart-rows">
				<ChartHolderDash charttitle="10+ Breakfast Station Listened To Most" source="Xtra Insights: 10+ All People, Station Listened To Most, Mon-Fri 0530-0900" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10bfmost" :chartcolors="chartcolors10bfmost" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10bfmost" :chartcolors="chartcolors10bfmost" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="10+ Drive Station Listened To Most" source="Xtra Insights: 10+ All People, Station Listened To Most, Mon-Fri 1600-1900" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata10dvmost" :chartcolors="chartcolors10dvmost" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata10dvmost" :chartcolors="chartcolors10dvmost" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="mktob.surveyed === 1 && !gfkmktids.includes(mktob.id) && latestSurv" class="chart-rows">
				<ChartHolderDash charttitle="25-54 Station Listened To Most" source="Xtra Insights: 25-54, Station Listened To Most, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata2554sltm" :chartcolors="chartcolors2554sltm" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata2554sltm" :chartcolors="chartcolors2554sltm" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="25-54 Cume 00s" source="Xtra Insights: 25-54, Cume 00s, Mon-Sun All Times" :height="360" addclasses="half">
					<template v-slot:default>
						<ApexFreqLineTrendMulti v-if="useLineChart" :chartdata="chartdata2554cume" :chartcolors="chartcolors2554cume" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false" :chartdata="chartdata2554cume" :chartcolors="chartcolors2554cume" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>
		</div>
	</div>
</template>

<script>
import StationLogo from "@/components/StationLogo";
import OwnershipLogo from "@/components/OwnershipLogo";
import axios from "axios";
import ChartHolderDash from "@/components/charts/ChartHolderDash";
import ApexFreqLineTrendMulti from "@/components/charts/ApexFreqLineTrendMulti";
import ApexFreqBar from "@/components/charts/ApexFreqBar";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "MarketSummary",
	components: {
		ApexFreqBar,
		ApexFreqLineTrendMulti,
		ChartHolderDash,
		OwnershipLogo,
		StationLogo,
	},
	mixins: [globalMixin],
	props: {
		mktob: Object,
	},
	data: function () {
		return {
			store: store,

			gfkmktids: [14,19,72,110,111,112,113],
			statisticIds: [2,11,4,7], //stnmost, cume00, bfastmost, drivemost
			fetchingCharts: true,
			defaultColorCount: 0,
			collatedMarketData: [],

			chartdata10sltm: [],
			chartcolors10sltm: [],

			chartdata10cume: [],
			chartcolors10cume: [],

			chartdata10bfmost: [],
			chartcolors10bfmost: [],

			chartdata10dvmost: [],
			chartcolors10dvmost: [],

			chartdata2554sltm: [],
			chartcolors2554sltm: [],

			chartdata2554cume: [],
			chartcolors2554cume: [],

			useLineChart: false,
		}
	},
	computed: {
		mapUrl() {
			return this.store.logosRoot + "mkt-maps/" + this.mktob.id + ".jpg";
		},
		comStations() {
			return this.store.stations.filter(item => item.mktid === this.mktob.id && item.isCommercial === 1 && item.community === 0 && item.outsidemkt === 0 && item.band !== 'dab' && item.band.length > 0);
		},
		surveys() {
			let s = this.store.surveys.filter(item => item.mktid === this.mktob.id && item.is_cra_gs === 1 && item.norelease !== 1);
			s = s.sort((a, b) => parseInt(a.reldate) - parseInt(b.reldate)); //sort oldest to newest
			return s;
		},
		latestSurv() {
			let latestSurv = this.surveys[this.surveys.length-1];
			if(latestSurv) return latestSurv;
			return false;
		},
		userCanViewFull() {
			if(this.latestSurv && this.latestSurv.userCanViewFull === true) {
				return true;
			}
			return false;
		},
		demoIds() {
			// if(this.userCanViewFull === true) {
			// 	return [48,78,255]; //10+, 25-54, GBs
			// }
			return [48,78];
		},
		lookupDetails() {
			let survids = [];
			for(let s=0; s<this.surveys.length; s++) {
				survids.push(this.surveys[s].id);
			}
			let stns = this.store.stations.filter(item => item.mktid === this.mktob.id && item.band !== 'dab' && item.outsidemkt !== 1 && item.community === 0 && (item.isCommercial === 1 || item.subnetown === 'abc_loc' || item.subnetown === 'abc_j'));
			let stationids = [];
			for(let s=0; s<stns.length; s++) {
				stationids.push(stns[s].id);
			}
			let settings = {
				vue: true,
				userob: this.store.user,
				markets: [this.mktob.id],
				surveys: survids,
				stations: stationids,
				demos: this.demoIds,
				statistics: this.statisticIds,
			};
			return settings;
		},
	},
	methods: {
		getChartData() { //for cache lookup
			this.fetchingCharts = true;
			let self = this;
			let lookupdets = {
				userob: this.store.user,
				markets: [this.mktob.id],
				surveys: this.lookupDetails.surveys,
				// stations: this.lookupDetails.stations,
				demoids: this.demoIds,
				statisticids: this.statisticIds,
			};
			let targ = self.store.apiroot + "arnstats/get-cache-data";
			axios.post(targ, JSON.stringify(lookupdets)
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.data) { //normal report type
					self.collatedMarketData = ret.data;
					self.prepareChartData('sltm10');
					self.prepareChartData('cume10');
					self.prepareChartData('bfmost10');
					self.prepareChartData('dvmost10');
					self.prepareChartData('sltm2554');
					self.prepareChartData('cume2554');
				}
				if (ret.reterror) {
					self.store.reterrormsg = ret.reterror;
				}
				self.fetchingCharts = false;
			}).catch(function() {
				self.fetchingCharts = false;
			});
		},
		// getChartData() { //for direct database run lookup
		// 	this.fetchingCharts = true;
		// 	let self = this;
		// 	let targ = self.store.calcEngineRoot + "run-ratings-calcs";
		// 	axios.post(targ, JSON.stringify(this.lookupDetails)
		// 	).then(function (response) {
		// 		var ret = response.data; //response.data is returned info
		// 		if (ret.collatedNew) { //normal report type
		// 			self.collatedMarketData = ret.collatedNew;
		// 			self.prepareChartData('sltm10');
		// 			self.prepareChartData('cume10');
		// 			self.prepareChartData('bfmost10');
		// 			self.prepareChartData('dvmost10');
		// 			self.prepareChartData('sltm2554');
		// 			self.prepareChartData('cume2554');
		// 		}
		// 		if (ret.reterror) {
		// 			self.store.reterrormsg = ret.reterror;
		// 		}
		// 		self.fetchingCharts = false;
		// 	}).catch(function() {
		// 		self.fetchingCharts = false;
		// 	});
		// },
		prepareChartData(name) {
			let cdata = [];
			let colors = [];
			this.defaultColorCount = 0;
			let statid = undefined;
			let demoid = undefined;
			if(name === 'sltm10') {
				statid = 2;
				demoid = 48;
			}
			else if(name === 'cume10') {
				statid = 11;
				demoid = 48;
			}
			else if(name === 'bfmost10') {
				statid = 4;
				demoid = 48;
			}
			else if(name === 'dvmost10') {
				statid = 7;
				demoid = 48;
			}
			else if(name === 'sltm2554') {
				statid = 2;
				demoid = 78;
			}
			else if(name === 'cume2554') {
				statid = 11;
				demoid = 78;
			}
			if(demoid !== undefined && statid !== undefined) {
				for (let s = 0; s < this.lookupDetails.stations.length; s++) {
					let cseries = {};
					let stid = this.lookupDetails.stations[s];
					let stnob = this.store.stations.find(item => item.id === stid);
					if(stnob.band.length > 0) {
						cseries.name = stnob.name;
						cseries.data = [];
						colors.push(this.getColor(stnob));
						// for (let su = 0; su < this.lookupDetails.surveys.length; su++) { //database run
						// 	let suid = this.lookupDetails.surveys[su];
						// 	let suob = this.store.surveys.find(item => item.id === suid);
						// 	let cell = this.collatedMarketData.find(item => item.suid === suid && item.demoid === demoid && item.statisticid === statid && item.stationid === stid);
						// 	cseries.data.push({
						// 		x: suob.name,
						// 		y: cell.val,
						// 	});
						// }
						let ob = this.collatedMarketData.find(item => item.demoid === demoid && item.statisticid === statid && item.stnid === stid);
						for (let i = ob.jsob.length - 1; i >= 0; i--) { //these are newest to oldest
							let cell = ob.jsob[i];
							let suid = cell.suid;
							let suob = this.store.surveys.find(item => item.id === suid);
							cseries.data.push({
								x: suob.name,
								y: cell.val,
							});
						}
						cdata.push(cseries);
					}
				}
				if(name === 'sltm10') {
					this.chartdata10sltm = cdata;
					this.chartcolors10sltm = colors;
				}
				else if(name === 'cume10') {
					this.chartdata10cume = cdata;
					this.chartcolors10cume = colors;
				}
				else if(name === 'bfmost10') {
					this.chartdata10bfmost = cdata;
					this.chartcolors10bfmost = colors;
				}
				else if(name === 'dvmost10') {
					this.chartdata10dvmost = cdata;
					this.chartcolors10dvmost = colors;
				}
				else if(name === 'sltm2554') {
					this.chartdata2554sltm = cdata;
					this.chartcolors2554sltm = colors;
				}
				else if(name === 'cume2554') {
					this.chartdata2554cume = cdata;
					this.chartcolors2554cume = colors;
				}
			}
		},
		getColor(stnob) {
			let l = this.store.netcolors.find(item => item.key === stnob.repsubnet);
			if(l) return l.col;
			l = this.store.netcolors.find(item => item.key === stnob.subnetown);
			if(l) return l.col;
			let col = this.store.defaultColors[this.defaultColorCount];
			this.defaultColorCount++;
			return col;
		},
	},
	watch: {},
	mounted() {
		setTimeout(function() {
			this.getChartData();
		}.bind(this), 150);
	}
}
</script>

<style scoped>
.market-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.top-content {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.station-cards {
	display: flex;
	gap: 20px;
	text-align: center;
	flex-wrap: wrap;
}
.station-card {
	background-color: #EFEFEF;
	border-radius: 10px;
	padding: 20px;
	width: 160px
}
.stnlogohold {
	width: 120px;
	height: 120px;
	padding: 10px;
}
.chart-rows {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}
.half {
	width: 49%;
	min-width: 49%;
}
.content-block {
	text-align: left;
}
.map-holder {
	text-align: right;
}
.content-block p {
	line-height: 1.6;
}

@media screen and (max-width: 1199px) {
	.top-content {
		display: block;
	}
	.top-content .half {
		width: 100%;
		min-width: 100%;
	}
	.map-holder {
		margin-top: 30px;
		text-align: left;
	}
	.chart-rows .half {
		width: 100%;
		min-width: 100%;
	}
}
</style>