<template>
	<div id="app">
		<AlertTop v-if="store.kalert.shown" v-bind:obj="store.kalert"></AlertTop>

		<LoaderOverlay v-if="store.showLoader"></LoaderOverlay>

		<router-view></router-view>

	</div>
</template>

<script>
import AlertTop from "@/components/AlertTop";
import axios from "axios";
import LoaderOverlay from "@/components/LoaderOverlay";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'App',
	components: {
		LoaderOverlay,
		AlertTop
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			requestedRoute: '',
		}
	},
	computed: {
		apiroot() {
			return this.store.apiroot;
		},
		selectedMarketObs() {
			return this.store.selectionObs.markets;
		},
		selectedSurveyObs() {
			return this.store.selectionObs.surveys;
		},
		selectedStationObs() {
			return this.store.selectionObs.stations;
		},
		selectedDemoObs() {
			return this.store.selectionObs.demos;
		},
		selectedStatisticObs() {
			return this.store.selectionObs.statistics;
		},
		survOldestLeft() {
			return this.store.survOldestLeft;
		},
		selectedTableLayout() {
			return this.store.selectedTableLayout;
		},
		// selectedScheduleInfo() {
		//
		// },
	},
	methods: {
		sesscheck() { //this is called on F5/load
			if(this.$router.currentRoute.path !== '/login') { //login handles its own stuff
				let localemail = window.localStorage.getItem('useremail');
				let localtoken = window.localStorage.getItem('usertoken');
				if (localemail && localemail.length > 0 && localtoken && localtoken.length > 0) {
					var self = this;
					axios.post(
						self.apiroot + "do-frequency-login",
						JSON.stringify({
							email: localemail,
							pass: localtoken,
						})
					).then(function(response) {
						var ret = response.data; //response.data is returned info
						if(ret.rruser) { //if this comes back we have logged in successfully
							self.setUser(ret.rruser);
							if(self.$route.path !== '/') {
								self.$router.push('/');
							}
						}
						if(ret.error === 1) {
							self.form1working = false;
							self.showKalert(ret.errob);
							if(self.$route.path !== '/login') {
								self.$router.push('/login');
							}
						}
					}).catch(function(error) {
						console.log(error);
					});
				}
				else { //local tokens do not exist, push to login screen
					if(this.$route.path !== '/login') {
						this.$router.push('/login');
					}
				}
			}
		},
	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.path;
		this.sesscheck();
	},
	created() {
		//handle incoming GET parameters - there might be 'email' and 'token' vals passed
		let urlparams = this.$route.query;
		this.store.getparams = urlparams;
	},
	watch: {
		$route() {
			//force close alert on page change
			this.closeKalert();
		},
		selectedMarketObs() {
			//update the survey selections
			let newsids = [];
			for(let mys of this.store.selectionObs.surveys) {
				if(mys && mys.mktid) {
					if(this.store.selectionObs.markets.some(item => item.id === mys.mktid)) {
						newsids.push(mys);
					}
				}
			}
			this.store.selectionObs.surveys = newsids;
			//update the station selections
			let newstids = [];
			for(let mys of this.store.selectionObs.stations) {
				if(mys && mys.mktid) {
					if(this.store.selectionObs.markets.some(item => item.id === mys.mktid)) {
						newstids.push(mys.id);
					}
				}
				if(mys && mys.id > 10000) { //combo
					newstids.push(mys.id);
				}
			}
			this.store.selectionObs.stations = this.store.stations.filter(item => newstids.includes(item.id));
			this.store.selectionObs.stations = this.store.selectionObs.stations.concat(this.store.stationcombos.filter(item => newstids.includes(item.id)));
			if(this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
		},
		selectedSurveyObs() {
			//update the statistic selections (to ensure all are available for the surveys chosen)
			let newstats = [];
			for(let mys of this.store.selectionObs.statistics) {
				if(this.isStatisticAvailableForSelectedSurveys(mys)) {
					newstats.push(mys);
				}
			}
			this.store.selectionObs.statistics = newstats;
			if(this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
		},
		selectedStationObs() {
			if(this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
		},
		selectedDemoObs() {
			if(this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
		},
		selectedStatisticObs() {
			if(this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
		},
		selectedTableLayout() {
			this.redoHighlightsIfChosen();
		},
		survOldestLeft() {

		},
	},
}
</script>

<style>
#app {

}
</style>


