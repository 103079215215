<template>
	<div class="mainschedrow">
		<div class="colsched firstcol" style="display: flex; padding: 0 5px; align-items: center; justify-content: flex-start; white-space: nowrap; overflow: hidden; word-break: break-word;">
			{{stnob.name}} <span v-if="mktob" style="font-size: 12px; color: #AAA; margin-left: 4px;">({{mktob.name}})</span>
		</div>
		<div data-daypartname="Breakfast" class="colsched"><input class="rfcellinput" type="number" v-model="breakfastnum" min="0"></div>
		<div data-daypartname="Morning" class="colsched"><input class="rfcellinput" type="number" v-model="morningnum" min="0"></div>
		<div data-daypartname="Afternoon" class="colsched"><input class="rfcellinput" type="number" v-model="afternoonnum" min="0"></div>
		<div data-daypartname="Drive" class="colsched"><input class="rfcellinput" type="number" v-model="drivenum" min="0"></div>
		<div data-daypartname="Night" class="colsched"><input class="rfcellinput" type="number" v-model="nightnum" min="0"></div>
		<div data-daypartname="Weekend" class="colsched border-right"><input class="rfcellinput" type="number" v-model="weekendnum" min="0"></div>
		<div class="td-fas">
			<i class="mdi mdi-content-copy" title="Copy this station's schedule to clipboard" @click="copyRowToClipboard"></i>
			<i class="mdi mdi-content-paste" title="Paste schedule to this row from clipboard" @click="pasteRowFromClipboard"></i>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ScheduleWeekStation",
	components: {},
	mixins: [globalMixin],
	props: {
		stationid: Number,
		weekid: Number,
	},
	data: function () {
		return {
			store: store,
			breakfastnum: null,
			morningnum: null,
			afternoonnum: null,
			drivenum: null,
			nightnum: null,
			weekendnum: null,
		}
	},
	computed: {
		stnob() {
			return this.store.stations.find(item => item.id === this.stationid);
		},
		mktob() {
			return this.store.markets.find(item => item.id === this.stnob.mktid);
		},
		json() {
			return {
				station: this.stationid,
				weekid: this.weekid,
				sessionSpots: [
					{daypart: 'Breakfast', nspots: this.breakfastnum},
					{daypart: 'Morning', nspots: this.morningnum},
					{daypart: 'Afternoon', nspots: this.afternoonnum},
					{daypart: 'Drive', nspots: this.drivenum},
					{daypart: 'Night', nspots: this.nightnum},
					{daypart: 'Weekend', nspots: this.weekendnum},
				],
			};
		},
		storeJson() {
			return this.store.rfjsonarr;
		},
	},
	methods: {
		copyRowToClipboard() {
			this.store.rfrowclipboard = {
				breakfastnum: this.breakfastnum,
				morningnum: this.morningnum,
				afternoonnum: this.afternoonnum,
				drivenum: this.drivenum,
				nightnum: this.nightnum,
				weekendnum: this.weekendnum,
			}
		},
		pasteRowFromClipboard() {
			if(this.store.rfrowclipboard) {
				let clip = this.store.rfrowclipboard;
				this.breakfastnum = clip.breakfastnum;
				this.morningnum = clip.morningnum;
				this.afternoonnum = clip.afternoonnum;
				this.drivenum = clip.drivenum;
				this.nightnum = clip.nightnum;
				this.weekendnum = clip.weekendnum;
			}
		},
		emitjson() {
			this.$emit('json', this.json);
		},
		updateFromStore() {
			let storeJsonWeekStationOb = this.storeJson.find(item => item.weekid === this.weekid && item.station === this.stationid);
			if(storeJsonWeekStationOb) {
				this.breakfastnum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Breakfast').nspots;
				this.morningnum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Morning').nspots;
				this.afternoonnum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Afternoon').nspots;
				this.drivenum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Drive').nspots;
				this.nightnum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Night').nspots;
				this.weekendnum = storeJsonWeekStationOb.sessionSpots.find(item => item.daypart === 'Weekend').nspots;
			}
		},
	},
	watch: {
		breakfastnum() { this.emitjson() },
		morningnum() { this.emitjson()},
		afternoonnum() { this.emitjson()},
		drivenum() { this.emitjson()},
		nightnum() { this.emitjson()},
		weekendnum() { this.emitjson()},
		storeJson() {
			this.updateFromStore();
		},
	},
	mounted() {
		this.updateFromStore();
		this.emitjson();
	}
}
</script>

<style scoped>
.rfcellinput {
	font-family: inherit;
	border: none;
	padding: 4px 8px;
	width: calc(100% - 2px);
	text-align: center;
}
.td-fas {
	padding: 0 5px;
	width: 44px;
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
}
.td-fas i {
	cursor: pointer;
}
.td-fas i:hover {
	color: #888;
}
</style>