<template>
	<div>
		<div class="chooser">
			<div class="left-side">
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter statistics..." v-model="filterText" class="filterbox" />
				</div>
				<div class="checkbox-list-hold">
					<p class="boldme grouphead">Statistics</p>
					<StatisticGroup v-for="groupob in statgroups" :key="groupob.name" :groupob="groupob" :filtertext="filterText"></StatisticGroup>
				</div>
			</div>
			<div class="right-side sortable-list">
				<draggable v-model="mylist" item-key="index">
					<template #item="{index}">
						<SortableInternal type="statistic" :objid="mylist[index].id" v-on:remove="toggleStatisticInSelection(mylist[index])"></SortableInternal>
					</template>
				</draggable>
			</div>
		</div>
	</div>
</template>

<script>
import StatisticGroup from "@/components/statistic-selections/StatisticGroup";
import SortableInternal from "@/components/SortableInternal";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Statistics",
	components: {
		SortableInternal,
		StatisticGroup,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',
			statgroups: [
				{name: 'overall', descrip: 'Mon-Sun all times'},
				{name: 'breakfast', descrip: 'Mon-Fri 5:30am to 9:00am'},
				{name: 'morning', descrip: 'Mon-Fri 9:00am to 12:00md'},
				{name: 'afternoon', descrip: 'Mon-Fri 12:00md-4:00pm'},
				{name: 'drive', descrip: 'Mon-Fri 4:00pm-7:00pm'},
				{name: 'night', descrip: 'Mon-Fri 7:00pm-12:00mn'},
				{name: 'weekend', descrip: 'Sat-Sun all times'},
				{name: 'work', descrip: 'When listener is at work'},
				{name: 'perceptual', descrip: ''},
				{name: 'other', descrip: ''},
			],
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
		mylist: {
			get() {
				return this.store.selectionObs.statistics;
			},
			set(value) {
				this.store.selectionObs.statistics = value;
			}
		},
		allStatistics() {
			return this.store.statistics;
		},
	},
	methods: {},
	watch: {},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {

}
.chooser {
	display: flex;
}
.left-side, .right-side {
	height: calc(100vh - 200px);
	overflow-y: auto;
}
.left-side {
	width: 500px;
}
.right-side {
	margin-left: 50px;
	min-height: 500px;
	width: 500px;
	padding: 5px 0;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	box-shadow: 0 0 6px 1px #e8e8e8 inset;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
</style>