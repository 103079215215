<template>
	<div class="rtblHolder">

		<TableHeaderContent :filtereddata="filteredData" :objin="objin"></TableHeaderContent>

		<table class="std-tbl" :id="'tbl'+ind">
			<thead>
				<tr>
					<td @click="clearSort" title="Revert to original order"></td>
					<td @click="sortColumn(index)" v-for="(ci, index) in columnItems" :key="index" title="Sort by this column">{{ci.name}}</td>
					<td v-if="showTotalColumn">Mkt Total</td>
					<td v-if="showMultiMarketColumns">Total Cume 00s</td>
					<td v-if="showMultiMarketColumns">Total Cume %</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(ri, index) in rowItems" :key="index">
					<td>{{ri.name}}</td>
					<td v-for="cii in columnItems" :key="cii.id">
						<ResultCell :filtereddata="filteredData" :obs="[cii, ri, tblItem, d4item]"></ResultCell>
					</td>
					<td v-if="showTotalColumn">
						<ResultCell :filtereddata="filteredData" type="total" :obs="[columnItems[0], ri, tblItem, d4item]"></ResultCell>
					</td>

					<td v-if="showMultiMarketColumns">
						<ResultCell :filtereddata="filteredData" type="multimarketcume00s" :obs="[columnItems[0], ri, tblItem, d4item]"></ResultCell>
					</td>
					<td v-if="showMultiMarketColumns">
						<ResultCell :filtereddata="filteredData" type="multimarketcumepct" :obs="[columnItems[0], ri, tblItem, d4item]"></ResultCell>
					</td>
				</tr>

				<tr v-if="showExclusiveRows" class="norank">
					<td>Listen to multiple stations</td>
					<td v-for="cii in columnItems" :key="cii.id" class="norank">
						<ResultCell :filtereddata="filteredData" type="multilisten" :obs="[cii, rowItems[0], tblItem, d4item]"></ResultCell>
					</td>
				</tr>

				<tr v-if="showExclusiveRows" class="norank">
					<td>Do not listen to daypart</td>
					<td v-for="cii in columnItems" :key="cii.id" class="norank">
						<ResultCell :filtereddata="filteredData" type="nolisten" :obs="[cii, rowItems[0], tblItem, d4item]"></ResultCell>
					</td>
				</tr>

				<tr v-if="showTotalRow" class="norank">
					<td>Mkt Total</td>
					<td v-for="cii in columnItems" :key="cii.id" class="norank">
						<ResultCell :filtereddata="filteredData" type="total" :obs="[cii, rowItems[0], tblItem, d4item]"></ResultCell>
					</td>
					<td v-if="showMultiMarketColumns">
						<ResultCell :filtereddata="filteredData" type="multimarketcume00stotal" :obs="[columnItems[0], rowItems[0], tblItem, d4item]"></ResultCell>
					</td>
					<td v-if="showMultiMarketColumns">
						<span>100.0</span>
					</td>
				</tr>

			</tbody>
		</table>

		<p style="font-size: 11px; color: #888; cursor: pointer; margin: 15px 0;" @click="toggleHighlights">
			<span v-if="store.showGreenHighlights">Clear highlights</span>
			<span v-else>Highlight by rank</span>
		</p>
	</div>
</template>

<script>
import ResultCell from "@/components/result-tables/ResultCell";
import TableHeaderContent from "@/components/result-tables/TableHeaderContent";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ResultTable",
	components: {
		TableHeaderContent,
		ResultCell
	},
	mixins: [globalMixin],
	props: {
		objin: Object,
		ind: Number,
	},
	data: function () {
		return {
			store: store,
			sortbycol: null,
			rowSortedIdsOverride: [],
		}
	},
	computed: {
		returnedData() {
			return this.store.returnedData;
		},
		filteredData() { //filter here to minimise what the resultcell has to do
			let demoIds = [];
			if(this.objin.demos.id) demoIds = [this.objin.demos.id];
			else {
				for(let ob of this.objin.demos) {
					demoIds.push(ob.id);
				}
			}
			let survids = [];
			if(this.objin.surveys.id) survids = [this.objin.surveys.id];
			else {
				for(let ob of this.objin.surveys) {
					survids.push(ob.id);
				}
			}
			let stationids = [];
			if(this.objin.stations.id) stationids = [this.objin.stations.id];
			else {
				for(let ob of this.objin.stations) {
					stationids.push(ob.id);
				}
			}
			let statisticids = [];
			if(this.objin.statistics.id) statisticids = [this.objin.statistics.id];
			else {
				for(let ob of this.objin.statistics) {
					statisticids.push(ob.id);
				}
			}
			return this.store.returnedData.filter(item =>
				demoIds.includes(item.demoid) && stationids.includes(item.stationid) && survids.includes(item.suid) && statisticids.includes(item.statisticid)
			);
		},
		resultMeta() {
			return this.store.resultMeta;
		},
		layout() {
			return this.store.selectedTableLayout;
		},
		columnItems() {
			return this.objin[this.objin.col];
		},
		rowItems() {
			if(this.rowSortedIdsOverride.length === 0) { //default
				return this.objin[this.objin.row];
			}
			else {
				return this.rowSortedIdsOverride;
			}
		},
		tblItem() {
			return this.objin[this.objin.table];
		},
		d4item() {
			return this.objin[this.objin.d4];
		},
		showTotalRow() {
			if(this.layout.row === 'statistics') {
				return false;
			}
			return true;
		},
		showTotalColumn() {
			if(this.showTotalRow === true) return false;
			if(this.layout.col === 'stations') return true;
			return false;
		},
		numMarketsActiveInTable() {
			let mids = [];
			if(this.objin && this.objin.surveys) {
				if (this.objin.surveys.id) return 1; //single market (object passed)
				for (let i = 0; i < this.objin.surveys.length; i++) { //array passed - check if more than one market is referenced
					let mysurv = this.objin.surveys[i];
					let mymktid = mysurv.mktid;
					if (!mids.includes(mymktid)) {
						mids.push(mymktid);
					}
				}
				return mids.length;
			}
			return 0;
		},
		showExclusiveRows() {
			if(this.showTotalRow === false) return false;
			if(this.objin.statistics.id) { //single statistic object in this table
				if(this.objin.statistics.isExclusive === 1) return true;
			}
			else {
				for (let i=0; i<this.objin.statistics.length; i++) {
					if(this.objin.statistics[i].isExclusive === 1) return true;
				}
			}
			return false;
		},
		showMultiMarketColumns() {
			if(this.layout.col === 'statistics' || this.layout.row === 'statistics') return false;
			if(this.objin.statistics.id) { //single statistic object in this table
				if(this.objin.statistics.allowedMultiMkt === 1 && this.numMarketsActiveInTable > 1) return true;
			}
			else {
				for (let i=0; i<this.objin.statistics.length; i++) {
					if(this.objin.statistics[i].allowedMultiMkt === 1 && this.numMarketsActiveInTable > 1) return true;
				}
			}
			return false;
		},
	},
	methods: {
		sortColumn(colid) {
			this.sortbycol = colid;
			let vals = [];
			let mytbl = document.querySelector('#tbl'+this.ind);
			let myrows = mytbl.querySelectorAll("tbody tr:not(.norank)");
			if(myrows[0]) {
				let c = this.sortbycol + 1;
				for (let r = 0; r < myrows.length; r++) { //looping through the rows within the col
					let myrow = myrows[r];
					let mycols = myrow.querySelectorAll("td:not(.norank)");
					let mycol = mycols[c];
					let thisVal = mycol.innerText;
					vals.push({obj: this.rowItems[r], val: parseFloat(thisVal)});
				}
				let sortedvals = vals.sort((b, a) => a.val - b.val);
				let newrows = [];
				for(let m=0; m<sortedvals.length; m++) {
					newrows.push(sortedvals[m].obj);
				}
				this.rowSortedIdsOverride = newrows;
				this.redoHighlightsIfChosen();
			}
		},
		clearSort() {
			this.rowSortedIdsOverride = [];
			this.redoHighlightsIfChosen();
		},
		toggleHighlights() {
			if(this.store.showGreenHighlights) {
				this.clearHighlights(); //mixin
				this.store.showGreenHighlights = false;
			}
			else {
				this.store.showGreenHighlights = true;
				this.highlightByCol(); //mixin
			}
		},
		// getCell(survid, stnid, demoid, statisticid) {
		// 	if(survid > 0 && stnid > 0 && demoid > 0 && statisticid > 0) {
		// 		let cell = this.store.returnedData.find(item =>
		// 			item.demoid === demoid && item.stationid === stnid && item.suid === survid && item.statisticid === statisticid
		// 		);
		// 		return cell;
		// 	}
		// 	return false;
		// },
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.rtblHolder {
	margin-bottom: 40px;
}
.std-tbl thead td {
	cursor: default;
}
</style>