<template>
	<span>
		{{val}}
	</span>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ResultCell",
	components: {},
	mixins: [globalMixin],
	props: {
		obs: Array,
		type: String,
		filtereddata: Array,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		survob() {
			return this.obs.find(item => item.type === 'survey');
		},
		survid() {
			if(this.survob) return this.survob.id;
			return false;
		},
		stationob() {
			return this.obs.find(item => item.type === 'station');
		},
		stnid() {
			if(this.stationob) return this.stationob.id;
			return false;
		},
		demoob() {
			return this.obs.find(item => item.type === 'demo');
		},
		demoid() {
			if(this.demoob) return this.demoob.id;
			return false;
		},
		statisticob() {
			return this.obs.find(item => item.type === 'statistic');
		},
		statisticid() {
			if(this.statisticob) return this.statisticob.id;
			return false;
		},
		isCumeHundreds() {
			if(this.statisticob && this.statisticob.isCumeHundreds === 1) return true;
			return false;
		},
		val() {
			let myval = '';
			if(this.type === 'total' && this.metacell) {
				myval = this.metacell.total;
			}
			else if(this.type === 'samplesize' && this.metacell) {
				return this.numdisplay(this.metacell.sampleSize);
			}
			else if(this.type === 'cumetotal' && this.metacell) {
				return this.numdisplay(this.metacell.cumeTotal);
			}
			else if(this.type === 'multilisten' && this.metacell) {
				myval = this.metacell.multilisten;
			}
			else if(this.type === 'nolisten' && this.metacell) {
				myval = this.metacell.nolisten;
			}
			else if(this.type === 'multimarketcume00s') {
				if(this.statisticob.allowedMultiMkt === 1 && this.stnid > 10000 && this.multimarketcell) return this.numdisplay(this.multimarketcell.val);
				return '';
			}
			else if(this.type === 'multimarketcumepct') {
				if(this.statisticob.allowedMultiMkt === 1 && this.stnid > 10000 && this.multimarketcell) return this.multimarketcell.pct;
				return '';
			}
			else if(this.type === 'multimarketcume00stotal') {
				if(this.statisticob.allowedMultiMkt === 1 && this.multimarkettotcell) return this.numdisplay(this.multimarkettotcell.baseTotal);
				return '';
			}
			else if(this.survid && this.stnid && this.demoid && this.statisticid) {
				let cell = this.filtereddata.find(item =>
					item.demoid === this.demoid && item.stationid === this.stnid && item.suid === this.survid && item.statisticid === this.statisticid
				);
				if(cell) myval = cell.val;
			}
			if(myval === '-') return myval;
			if(myval !== '' && myval >= 0) {
				if(this.isCumeHundreds) return this.numdisplay(myval);
				else return this.numdisplayNdp(myval, 1);
			}
			return '';
		},
		metacell() {
			return this.store.resultMeta.find(item => item.suid === this.survid && item.demoid === this.demoid && item.statisticid === this.statisticid);
		},
		multimarketcell() {
			return this.store.multiMarketCumeReturn.find(item => item.stationid === this.stnid && item.demoid === this.demoid && item.statisticid === this.statisticid);
		},
		multimarkettotcell() { //no need to match station for this one
			return this.store.multiMarketCumeReturn.find(item => item.demoid === this.demoid && item.statisticid === this.statisticid);
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>