<template>
	<div style="margin-bottom: 20px;">
		<div v-if="warningsHtml.length > 0" v-html="warningsHtml" style="margin-bottom: 10px;"></div>

		<p><span class="orangetxt">Survey: </span>{{surveyHeadingName}}</p>
		<p><span class="orangetxt">Demographic: </span>{{demoHeadingName}}</p>
		<p v-if="objin.stations.id"><span class="orangetxt">Station: </span>
			{{stationName}}
		</p>
		<p v-if="objin.statistics.id"><span class="orangetxt">Statistic: </span>{{statisticHeadingName}}</p>
		<p v-if="objin.surveys.id && objin.demos.id"><span class="orangetxt">Active sample: </span>
			<ResultCell :filtereddata="filtereddata" type="samplesize" :obs="[columnItems[0], rowItems[0], tblItem, d4item]"></ResultCell>
		</p>
		<p v-if="objin.surveys.id && objin.demos.id"><span class="orangetxt">Total cume from sample: </span>
			<ResultCell :filtereddata="filtereddata" type="cumetotal" :obs="[columnItems[0], rowItems[0], tblItem, d4item]"></ResultCell>
		</p>
		<p v-if="store.selectionObs.markets.length > 1"><span class="orangetxt">Source Note: </span>Multi market network cume reach does not include spillover network stations, and does not take into account minor geographic market overlap.</p>
	</div>
</template>

<script>
import ResultCell from "@/components/result-tables/ResultCell";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TableHeaderContent",
	components: {
		ResultCell
	},
	mixins: [globalMixin],
	props: {
		objin: Object,
		filtereddata: Array,
	},
	data: function() {
		return {
			store: store,
		}
	},
	computed: {
		stationName() {
			let stn = this.store.stations.find(item => item.id === this.objin.stations.id);
			if(stn !== undefined) return stn.name;
			stn = this.store.stationcombos.find(item => item.id === this.objin.stations.id);
			if(stn !== undefined) return stn.name;
			return '';
		},
		layout() {
			return this.store.selectedTableLayout;
		},
		surveyHeadingName() {
			if(this.objin.surveys.id) { //just a single survey for this table
				return this.objin.surveys.name;
			}
			else if(this.objin.surveys.length === 1) {
				return this.objin.surveys[0].name;
			}
			return 'As Listed';
		},
		demoHeadingName() {
			if(this.layout.table === 'demos' || this.layout.d4 === 'demos') {
				let name = this.objin.demos.name; //single item
				if(this.doesDemoNeedSampleWarning(this.objin.demos.id) === true) {
					name += "*";
				}
				return name;
			}
			return 'As Listed';
		},
		statisticHeadingName() {
			if(this.layout.table === 'statistics' || this.layout.d4 === 'statistics') {
				return this.objin.statistics.name; //single item
			}
			return 'As Listed';
		},
		columnItems() {
			return this.objin[this.objin.col];
		},
		rowItems() {
			return this.objin[this.objin.row];
		},
		tblItem() {
			return this.objin[this.objin.table];
		},
		d4item() {
			return this.objin[this.objin.d4];
		},
		warningsHtml() {
			let w = '';
			for(let i=0; i<this.store.returnedWarnings.length; i++) {
				let myw = this.store.returnedWarnings[i];
				if(myw.length > 0) {
					w += myw;
				}
			}
			return w;
		},
	},
	methods: {

	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>

</style>