<template>
	<div class="view-holder">
		<div class="chooser">
			<div class="left-side">
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter demographics..." v-model="filterText" class="filterbox" />
				</div>
				<div class="checkbox-list-hold">
					<p class="boldme grouphead">Global Demographics</p>
					<div v-for="dem in filteredGlobalDemos" :key="dem.id" class="checklist-item">
						<div class="ch-and-title" @click="toggleDemoInSelection(dem)">
							<span class="toggler">
								<i v-if="store.selectionObs.demos.some(item => item.id === dem.id)" class="mdi mdi-check-bold"></i>
							</span>
							<span class="label" :id="'dem'+dem.id">{{dem.name}}</span>
						</div>
					</div>

					<p class="boldme grouphead">User Saved</p>
					<DemoViewGroup :demos="filteredUserSavedAllSurveyDemos" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>

					<p class="boldme grouphead">Global Survey Specific</p>
					<div v-for="dem in filteredSurveySpecificGlobalDemos" :key="dem.id" class="checklist-item">
						<div class="ch-and-title" @click="toggleDemoInSelection(dem)">
							<span class="toggler">
								<i v-if="store.selectionObs.demos.some(item => item.id === dem.id)" class="mdi mdi-check-bold"></i>
							</span>
							<span class="label" :id="'dem'+dem.id">{{dem.name}}</span>
						</div>
					</div>

					<p class="boldme grouphead">User Saved Survey Specific</p>
					<DemoViewGroup :demos="filteredSurveySpecificUserDemos" v-on:toggledemo="toggleDemoInSelection($event)"></DemoViewGroup>

				</div>
			</div>

			<div class="right-side sortable-list">
				<draggable v-model="mylist" item-key="index">
					<template #item="{index}">
						<SortableInternal type="demographic" :objid="mylist[index].id" v-on:remove="toggleDemoInSelection(mylist[index])"></SortableInternal>
					</template>
				</draggable>
			</div>

		</div>

	</div>
</template>

<script>
import SortableInternal from "@/components/SortableInternal";
import DemoViewGroup from "@/components/demos/DemoViewGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Demos",
	components: {
		DemoViewGroup,
		SortableInternal,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',
		}
	},
	computed: {
		mylist: {
			get() {
				return this.store.selectionObs.demos;
			},
			set(value) {
				this.store.selectionObs.demos = value;
			}
		},
		reportType() {
			return this.store.reportType;
		},
		surveyDatalocs() {
			let sl = [];
			for(let s of this.store.selectionObs.surveys) {
				sl.push(s.dataloc)
			}
			return sl
		},
		filteredGlobalDemos() {
			let mydemos = this.store.demos.filter(item => (item.userid === 0 && item.survid === 0));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demos.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredUserSavedAllSurveyDemos() {
			let mydemos = this.store.demos.filter(item => (item.userid === this.store.user.id && item.survid === 0));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demos.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificGlobalDemos() {
			let mydemos = this.store.demos.filter(item => (item.userid === 0 && this.surveyDatalocs.includes(item.survid)));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demos.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificUserDemos() {
			let mydemos = this.store.demos.filter(item => (item.userid === this.store.user.id && this.surveyDatalocs.includes(item.survid)));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demos.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {},
	watch: {},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}
.chooser {
	display: flex;
}
.left-side, .right-side {
	height: calc(100vh - 200px);
	overflow-y: auto;
}
.left-side {
	width: 500px;
}
.right-side {
	margin-left: 50px;
	min-height: 500px;
	width: 500px;
	padding: 5px 0;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	box-shadow: 0 0 6px 1px #e8e8e8 inset;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
</style>