<template>
	<div v-if="store.user.id > 0">
		<MenuBar></MenuBar>
		<TopTabBar v-if="store.reportType"></TopTabBar>

		<JobsManage v-if="store.jobManageOverlayShown"></JobsManage>
		<BatchManage v-if="store.batchManageOverlayShown"></BatchManage>
		<CombosManage v-if="store.combosManageOverlayShown"></CombosManage>
		<DemosManage v-if="store.demosManageOverlayShown"></DemosManage>
		<DemoCreate v-if="store.demoNewOverlayShown"></DemoCreate>
		<FreqChartExplorer v-if="store.chartExplorerOverlayShown"></FreqChartExplorer>

		<div class="body-hold" :class="{tabsNotShown : !store.reportType}">
			<ReportTypeChooser v-if="store.reportType === null"></ReportTypeChooser>
			<div v-else>
				<Markets v-if="store.activeTab === 'Markets'"></Markets>
				<Surveys v-if="store.activeTab === 'Surveys'"></Surveys>
				<Stations v-if="store.activeTab === 'Stations'"></Stations>
				<Demos v-if="store.activeTab === 'Demos'"></Demos>
				<Statistics v-if="store.activeTab === 'Statistics'"></Statistics>
				<Schedule v-if="store.activeTab === 'Schedule'"></Schedule>
				<Results v-if="store.activeTab === 'Results'"></Results>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import MenuBar from "@/components/MenuBar";
import TopTabBar from "@/components/TopTabBar";
import ReportTypeChooser from "@/components/ReportTypeChooser";
import Markets from "@/components/views/Markets";
import Stations from "@/components/views/Stations";
import Surveys from "@/components/views/Surveys";
import Demos from "@/components/views/Demos";
import Statistics from "@/components/views/Statistics";
import Schedule from "@/components/views/Schedule";
import Results from "@/components/views/Results";
import JobsManage from "@/components/overlays/JobsManage";
import BatchManage from "@/components/overlays/BatchManage";
import CombosManage from "@/components/overlays/CombosManage";
import DemosManage from "@/components/overlays/DemosManage";
import DemoCreate from "@/components/overlays/DemoCreate";
import FreqChartExplorer from "@/components/views/FreqChartExplorer";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "HomePage",
	components: {
		FreqChartExplorer,
		DemoCreate,
		DemosManage,
		CombosManage,
		BatchManage,
		JobsManage,
		Results,
		Schedule,
		Statistics,
		Demos,
		Surveys,
		Stations,
		Markets,
		ReportTypeChooser,
		TopTabBar,
		MenuBar
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingInitial: false,
		}
	},
	computed: {
		userid() {
			return this.store.user.id;
		},
		apiroot() {
			return this.store.apiroot;
		},
	},
	methods: {
		getInitialObjects() {
			if(this.fetchingInitial === false) {
				this.fetchingInitial = true;
				var self = this;
				axios.post(
					self.apiroot + "get-initial-objects",
					JSON.stringify({
						userob: self.store.user,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.markets) { //handle returned objects for lists
						self.setObjectLists(ret);
					}
					if (ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret.errob)
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {
		userid() { //once we get a userid
			if(this.userid > 0) { //called after login/session confirmation
				this.getInitialObjects();
			}
		},
	},
	mounted() {
		document.title = "Frequency";
		if(this.userid > 0) { //to catch anyone who has been automatically logged in
			this.getInitialObjects();
		}
	}
}
</script>

<style scoped>
.body-hold {
	margin-top: 150px;
	padding: 20px 20px;
}
.body-hold.tabsNotShown {
	margin-top: 40px;
}
</style>