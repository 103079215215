
import axios from "axios";

export const globalMixin = {
	methods: {
		numdisplay: function (num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		ucfirst: function (str) {
			if(str.length === 0) return str;
			return str[0].toUpperCase() + str.substring(1);
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		zeroToBlank(str) {
			if (parseInt(str) === 0) {
				return '';
			}
			return str;
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		ntobr(str) {
			return str.replaceAll("\n", "<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (var i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
		stripTagsFromString(str) {
			return str.replace(/(<([^>]+)>)/gi, "");
		},
		clearLocalStorage() {
			window.localStorage.removeItem('useremail');
			window.localStorage.removeItem('usertoken');
		},
		joinObjectIds(obs, keyname = 'id', sep = '|') {
			let arr = this.getObIdsToArr(obs, keyname)
			return arr.join(sep)
		},
		getObIdsToArr: function (obs, keyname = 'id') {
			let arr = []
			for (let ob of obs) {
				if (ob && Object.prototype.hasOwnProperty.call(ob, keyname)) arr.push(ob[keyname])
			}
			return arr
		},
		getObjectsFromArrayById(inobarr, idarr) {
			let arr = []
			for(let id of idarr) {
				let ob = inobarr.find(item => item.id === id)
				if(ob) arr.push(ob)
			}
			return arr
		},
		getStationObjectsFromArrayById(idarr) {
			let arr = []
			for(let id of idarr) {
				if(id < 10000) arr.push(this.store.stations.find(item => item.id === id))
				else arr.push(this.store.stationcombos.find(item => item.id === id))
			}
			return arr
		},
		splitpipe(valstr) {
			let ret = [];
			if (valstr !== "") {
				if (isNaN(valstr)) {
					if (valstr.indexOf("|") > 0) {
						var temp = valstr.split("|");
						for (var i = 0; i < temp.length; i++) {
							var ti = parseInt(temp[i]);
							ret.push(ti);
						}
					}
					else {
						ret.push(parseInt(valstr));
					}
				}
				else {
					ret.push(valstr);
				}
			}
			return ret;
		},
		cleanMSWord(text) {
			var s = text;
			s = s.replace(/[\u2018|\u2019|\u201A]/g, "'"); // smart single quotes and apostrophe
			s = s.replace(/[\u201C|\u201D|\u201E]/g, "\""); // smart double quotes
			s = s.replace(/\u2026/g, "..."); // ellipsis
			s = s.replace(/[\u2013|\u2014]/g, "-"); // dashes
			s = s.replace(/\u02C6/g, "^"); // circumflex
			s = s.replace(/\u2039/g, ""); // open angle bracket
			s = s.replace(/[\u02DC|\u00A0]/g, " "); // spaces
			return s;
		},
		convertNetworkString(str) {
			if(str === 'arn') return 'ARN';
			else if(str === 'sca') return 'SCA';
			else if(str === 'ace') return 'Ace Radio';
			else if(str === 'abc') return 'ABC';
			else if(str === 'nova') return 'Nova Entertainment';
			else if(str === 'super') return 'Super Radio Network';
			else if(str === 'eon') return 'EON Broadcasters';
			else if(str === 'resonate') return 'Resonate';
			else if(str === 'capital') return 'Capital Radio';
			else if(str === 'gb') return 'Grant Broadcasters';
			else if(str === 'wcr') return 'West Coast Radio';
			else if(str === 'win') return 'WIN Radio';

			else if(str === 'arn_k') return 'KIIS Network';
			else if(str === 'arn_p') return 'Pure Gold Network';
			else if(str === 'sca_m') return 'Triple M Network';
			else if(str === 'sca_h') return 'Hit Network';
			else if(str === 'abc_loc') return 'Local Radio';
			else if(str === 'abc_rn') return 'Radio National';
			else if(str === 'abc_news') return 'News Radio';
			else if(str === 'abc_j') return 'Triple J';
			else if(str === 'abc_cl') return 'Classic FM';
			else if(str === 'ace_am') return 'Heritage Network';
			else if(str === 'ace_fm') return 'FM Network';

			return '';
		},
		toggleStationInSelection(ob) {
			console.log(ob)
			let temparr = this.store.selectionObs.stations;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.stations = temparr;
		},
		toggleMarketInSelection(ob) {
			let temparr = this.store.selectionObs.markets;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.markets = temparr;
		},
		toggleSurveyInSelection(ob) {
			let temparr = this.store.selectionObs.surveys;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.surveys = temparr;
		},
		toggleDemoInSelection(ob) {
			let temparr = this.store.selectionObs.demos;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.demos = temparr;
		},
		toggleStatisticInSelection(ob) {
			let temparr = this.store.selectionObs.statistics;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.statistics = temparr;
		},
		isStatisticAvailableForSelectedSurveys(statisticOb) {
			if(statisticOb.is_goldstd === 1) return true; //always available
			for(let i=0; i<this.store.selectionObs.surveys.length; i++) {
				if(statisticOb.availFor.includes(this.store.selectionObs.surveys[i].id)) return true;
			}
			return false;
		},
		getResults() {
			this.store.calcIsRunning = true;
			let needsStations = ['normal','reachFrequency'];
			let needsDemos = ['normal','reachFrequency','allQIDs'];
			let targ = "";
			let funclookup = '';
			if(this.store.reportType === 'normal') {
				targ = this.store.calcEngineRoot + "run-ratings-calcs";
			}
			else if(this.store.reportType === 'reachFrequency') {
				targ = this.store.calcEngineRoot + "rr-do-rf-vue"; //using the new one
			}
			else { //special reports
				targ = this.store.calcEngineRoot + "ratings-requests";
				if(this.store.reportType === 'allQIDs') funclookup = 'basicStats';
				if(this.store.reportType === 'StnDemoCalcs') funclookup = 'TSL';
				if(this.store.reportType === 'pctStnCume') funclookup = 'pctStnCume';
				if(this.store.reportType === 'stnCumeSharing') funclookup = 'stnCumeSharing';
				if(this.store.reportType === 'stnMostByCumeStn') funclookup = 'stnMostByCumeStn';
				if(this.store.reportType === 'ebbNFlow') funclookup = 'ebbNFlow';
			}

			if(targ !== "") {
				if(this.store.selectionObs.markets.length === 0) {
					this.showTempDangerAlert('Please choose a market');
					this.setActiveTab('Markets');
					return false;
				}
				if(this.store.selectionObs.surveys.length === 0) {
					this.showTempDangerAlert('Please choose at least one survey');
					this.setActiveTab('Surveys');
					return false;
				}
				if(this.store.selectionObs.stations.length === 0 && needsStations.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one station');
					this.setActiveTab('Stations');
					return false;
				}
				if(this.store.selectionObs.demos.length === 0 && needsDemos.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one demographic');
					this.setActiveTab('Demos');
					return false;
				}
				if(this.store.selectionObs.statistics.length === 0 && this.store.reportType === 'normal') {
					this.showTempDangerAlert('Please choose at least one statistic');
					this.setActiveTab('Statistics');
					return false;
				}
				let rfTotalSpotsComputed = this.getRfTotalSpotsComputed
				if(rfTotalSpotsComputed <= 0 && this.store.reportType === 'reachFrequency') {
					this.showTempDangerAlert('Please add some spots to your schedule');
					this.setActiveTab('Schedule');
					return false;
				}
				this.store.showLoader = true;
				this.store.reterrormsg = '';
				let rfjson = [];
				if(this.store.reportType === 'reachFrequency') {
					rfjson = JSON.stringify(this.store.rfjsonarr);
				}
				var self = this;
				//update markets list to only include markets that have surveys selected
				let initialMarketIds = this.getObIdsToArr(this.store.selectionObs.markets)
				let tempMktIds = [];
				let tempMkts = []
				for(let mys of this.store.selectionObs.surveys) {
					if(!tempMkts.some(item => item.id === mys.mktid)) {
						tempMkts.push(this.store.markets.find(item => item.id === mys.mktid));
						tempMktIds.push(mys.mktid)
					}
				}
				if(initialMarketIds.length !== tempMktIds.length) { //need update
					this.store.selectionObs.markets = tempMkts
				}
				else {
					let needsUpdate = false
					for(let mid of initialMarketIds) {
						if(!tempMktIds.includes(mid)) {
							needsUpdate = true
							break;
						}
					}
					if(needsUpdate === true) this.store.selectionObs.markets = tempMkts
				}

				if((self.store.toExcel === 1 || self.store.toPowerBi) && self.store.reportType !== 'reachFrequency') {
					var sendstring = "tblchoice=" + self.store.selectedTableLayout.table + "&rowchoice=" + self.store.selectedTableLayout.row +
						"&colChoice=" + self.store.selectedTableLayout.col + "&colSplitChoice=" + self.store.selectedTableLayout.colsplit +
						"&markets=" + self.joinObjectIds(self.store.selectionObs.markets) + "&surveys=" + self.joinObjectIds(self.store.selectionObs.surveys) +
						"&stations=" + self.joinObjectIds(self.store.selectionObs.stations) + "&demos=" + self.joinObjectIds(self.store.selectionObs.demos) +
						"&statistics=" + self.joinObjectIds(self.store.selectionObs.statistics) + "&toExcel=" + self.store.toExcel +
						"&survOldestLeft=" + self.store.survOldestLeft + "&powerbi="+self.store.toPowerBi +
						"&incomingFilename=" + self.store.jobname + "&runAsHighlights=" + self.store.runAsHighlights + "&vuexcel=1" +
						"&userid=" +self.store.user.id+ "&useremail=" +self.store.user.email+ "&usertoken=" + self.store.user.token +
						"&funclookup="+funclookup + "&rfjson="+rfjson+"&nweeks="+self.store.nweeksrf +
						"&rrweighting="+self.store.useWeighting+"&rrrawnum="+self.store.useRawNums;
					window.location = targ + "?" + sendstring;
					setTimeout(function() {
						self.store.calcIsRunning = false;
						self.store.showLoader = false;
						self.store.toExcel = 0;
						self.store.toPowerBi = 0;
					}.bind(self), 200);
				}
				else { //normal post call, or setup post for Excel Vue R&F
					axios.post(
						targ,
						JSON.stringify({
							vue: true,
							userob: self.store.user,
							markets: self.getObIdsToArr(self.store.selectionObs.markets),
							surveys: self.getObIdsToArr(self.store.selectionObs.surveys),
							stations: self.getObIdsToArr(self.store.selectionObs.stations),
							demos: self.getObIdsToArr(self.store.selectionObs.demos),
							statistics: self.getObIdsToArr(self.store.selectionObs.statistics),
							powerbi: self.store.toPowerBi,
							tblchoice: self.store.selectedTableLayout.table,
							rowchoice: self.store.selectedTableLayout.row,
							colChoice: self.store.selectedTableLayout.col,
							colSplitChoice: self.store.selectedTableLayout.colsplit,
							toExcel: self.store.toExcel,
							runAsHighlights: self.store.runAsHighlights,
							survOldestLeft: self.store.survOldestLeft,
							incomingFilename: self.store.jobname,
							funclookup: funclookup, //for ratings-requests
							nweeksrf: self.store.nweeksrf, //for r&f
							rfjson: rfjson,
							rrweighting: self.store.useWeighting,
							rrrawnum: self.store.useRawNums,
						})
					).then(function (response) {
						self.store.reterrormsg = '';
						self.store.rethtml = '';
						self.store.retrfproblems = [];
						var ret = response.data; //response.data is returned info
						if (ret.collatedNew) { //normal report type
							self.store.returnedData = ret.collatedNew;
							self.store.resultMeta = ret.resultMeta;
							self.store.returnedWarnings = ret.warnings;
							self.store.multiMarketCumeReturn = ret.multimarketcumes;
						}
						else if (ret.vuetables) { //special report type, basic table layout created
							self.store.returnedData = ret.vuetables;
							self.store.resultMeta = [];
							self.store.returnedWarnings = [];
							self.store.multiMarketCumeReturn = [];
						}
						if (ret.reterror) {
							self.store.reterrormsg = ret.reterror;
						}
						if(ret.html) {
							self.store.rethtml = ret.html;
						}
						if(ret.problems) {
							self.store.retrfproblems = ret.problems;
						}
						if(self.store.reportType === 'reachFrequency' && self.store.toExcel === 1) { //for R&F - trigger the next step
							self.fetchDownloadItem(targ, ret.dltoken, ret.dltime);
						}
						else {
							self.store.toExcel = 0;
							self.store.showLoader = false;
						}
						self.store.selectionsHaveChangedNotYetRun = false;
						self.store.toPowerBi = 0;
						self.store.resultTableShownIndex = 0; //reset to view first table
						if(self.store.activeTab !== 'Results') {
							self.store.activeTab = 'Results';
						}
						setTimeout(function() {
							self.store.calcIsRunning = false;
							if(self.store.showGreenHighlights) {
								self.highlightByCol();
							}
						}.bind(self), 200);
					}).catch(function (error) {
						console.log(error);
						self.store.showLoader = false;
					});
				}
			}
		},
		fetchDownloadItem(targ, dltoken, dltime) {
			let str = "?vuexcel=1&dltoken=" + dltoken + "&dltime=" + dltime +  "&userid=" +this.store.user.id+ "&useremail=" +this.store.user.email+ "&usertoken=" + this.store.user.token;
			window.location = targ + str;
			this.store.toExcel = 0;
			this.store.showLoader = false;
			this.store.calcIsRunning = false;
		},
		doesDemoNeedSampleWarning(demoid) { //used on table headers - broad for any demo warning
			let demmetas = this.store.resultMeta.filter(item => item.demoid === demoid);
			for(let i=0; i<demmetas.length; i++) {
				if(demmetas[i].sampleSize < 75) return true;
			}
			return false;
		},
		// doesDemoStatisticSurveyNeedSampleWarning(demoid, statisticid, surveyid) { //used in table cell labels
		// 	let demmetas = this.store.resultMeta.filter(item => item.demoid === demoid && item.statisticid === statisticid && item.suid === surveyid);
		// 	for(let i=0; i<demmetas.length; i++) {
		// 		if(demmetas[i].sampleSize < 75) return true;
		// 	}
		// 	return false;
		// },
		newJob() {
			this.store.jobname = '';
			this.store.jobid = 0;
			this.clearAllSelections();
		},
		clearAllSelections() {
			this.store.selectionObs = {
				markets: [],
				surveys: [],
				stations: [],
				demos: [],
				statistics: [],
			};
			this.store.activeTab = 'Markets';
			this.store.rfjsonarr = [];
		},
		convertRFScheduleFromVue() { //prepare to send to back-end / save job
			let out = []; //this is the standard format where all items sit together
			let rfjsonarr = this.store.rfjsonarr;
			for(let i=0; i<rfjsonarr.length; i++) { //this is an array of objects
				let myarr = rfjsonarr[i];
				for(let j=0; j<myarr.length; j++) {
					let myob = myarr[j];
					out.push(myob); //push the new ones in
				}
			}
			return out;
		},
		getTVAggMktName(aggmktid) {
			let m = this.store.tvaggmkts.find(item => item.id === aggmktid);
			if(m) return m.name;
			return '';
		},
		highlightByCol() {
			this.clearHighlights();
			let mytbls = document.querySelectorAll(".std-tbl"), i;
			for(i=0; i<mytbls.length; i++) {
				let mytbl = mytbls[i];
				let myrows = mytbl.querySelectorAll("tbody tr:not(.norank)");
				if(myrows[0]) {
					let tdCount = myrows[0].querySelectorAll("td:not(.norank)").length;
					for (let c = 1; c < tdCount; c++) { //go through each col
						let vals = [];
						for (let r = 0; r < myrows.length; r++) { //looping through the rows within the col
							let myrow = myrows[r];
							let mycols = myrow.querySelectorAll("td:not(.norank)");
							let mycol = mycols[c];
							if(mycol) {
								let thisVal = mycol.innerText;
								vals.push(parseFloat(thisVal));
							}
						}
						let sortedIndex = vals.sort(function (a, b) {
							return b - a;
						});
						let cls = ["high", "high2", "high3", "high4"];
						for (let p = 0; p < 4; p++) {
							let tval = sortedIndex[p]; //nth value
							let thiscl = cls[p];
							for (let rr = 0; rr < myrows.length; rr++) {
								let thisrow = myrows[rr];
								if (thisrow.querySelectorAll("td")[c].innerText == tval) {
									thisrow.querySelectorAll("td")[c].classList.add(thiscl);
								}
							}
						}
					}
				}
			}
		},
		clearHighlights() {
			let j = document.querySelectorAll(".std-tbl td");
			j.forEach(item => {item.classList.remove('high')});
			j.forEach(item => {item.classList.remove('high2')});
			j.forEach(item => {item.classList.remove('high3')});
			j.forEach(item => {item.classList.remove('high4')});
		},
		redoHighlightsIfChosen() {
			if(this.store.showGreenHighlights) {
				setTimeout(function() {
					this.highlightByCol();
				}.bind(this), 200);
			}
		},
		convertTableLayoutStringToOb(layoutstr) { //eg stations|statistics|demos from db
			let litems = ['statistics','stations','surveys','demos'];
			let layoutarr = layoutstr.split('|');
			let tb = layoutarr[0];
			let ro = layoutarr[1];
			let co = layoutarr[2];
			let colsp = '';
			if(layoutarr[3]) { //colsplit is set
				colsp = layoutarr[3];
			}
			let d4 = '';
			if(colsp.length === 0) { //put the final item in d4 instead
				for(let i=0; i<litems.length; i++) {
					if(d4.length === 0) {
						let my = litems[i];
						if (tb !== my && ro !== my && co !== my) {
							d4 = my;
						}
					}
				}
			}
			return {table: tb, row: ro, col: co, d4: d4, colsplit: colsp};
		},
		shortcutMarketsTrig(type, statename) {
			this.store.shortcutmarketTrigger = [type, statename];
		},

		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc', forceKeepIds = []) {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = filterText.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (forceKeepIds.includes(r.id) || r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		// tblGetPaginatedRows(rows, paginationRowsPerPage = 25) {
		// 	if(!paginationRowsPerPage) return [rows]
		// 	else {
		// 		let tempArr = []
		// 		let tempSubArr = []
		// 		for(let it of rows) {
		// 			tempSubArr.push(it)
		// 			if(tempSubArr.length === paginationRowsPerPage) {
		// 				tempArr.push(tempSubArr)
		// 				tempSubArr = []
		// 			}
		// 		}
		// 		if(tempSubArr.length > 0) tempArr.push(tempSubArr)
		// 		return tempArr
		// 	}
		// },
		//end tables stuff

		/// old store functions
		unsetReportType() {
			this.store.reportType = null;
		},
		setActiveTab(str) {
			this.store.activeTab = str;
		},
		setObjectLists(obj) {
			this.store.surveys = obj.surveys;
			this.store.stations = obj.stations;
			//loop through stations and overload market tags for ARN, SCA etc
			for(let m=0; m<obj.markets.length; m++) {
				let x = {
					owns: [],
					reps: [],
					arnown: false,
					scaown: false,
					arnrep: false,
					scarep: false,
				}
				let mymkt = obj.markets[m];
				let stnmkts = obj.stations.filter(item => mymkt.id === item.mktid && item.community === 0 && item.isOther === 0);
				for(let s=0; s<stnmkts.length; s++) {
					let mystn = stnmkts[s];
					if(!x.owns.includes(mystn.netgroupown) && mystn.netgroupown.length > 0) x.owns.push(mystn.netgroupown);
					if(!x.reps.includes(mystn.repnetgroup) && mystn.repnetgroup.length > 0) x.reps.push(mystn.repnetgroup);
					if(mystn.netgroupown === 'arn') x.arnown = true;
					if(mystn.netgroupown === 'sca') x.scaown = true;
					if(mystn.repnetgroup === 'arn') x.arnrep = true;
					if(mystn.repnetgroup === 'sca') x.scarep = true;
				}
				obj.markets[m].ownership = x;
			}
			this.store.markets = obj.markets;
			this.store.stationcombos = obj.stationcombos;
			this.store.demos = obj.demos;
			this.store.statistics = obj.statistics;
			this.store.tvaggmkts = obj.tvaggmkts;
			this.store.demofolders = obj.demofolders;
			this.store.combofolders = obj.combofolders;
		},
		toggleWeightingFlag() {
			if(this.store.useWeighting === 1) this.store.useWeighting = 0;
			else this.store.useWeighting = 1;
		},
		toggleRawNumFlag() {
			if(this.store.useRawNums === 1) this.store.useRawNums = 0;
			else this.store.useRawNums = 1;
		},
		toggleRunAsHighlightsFlag() {
			if(this.store.runAsHighlights === 1) this.store.runAsHighlights = 0;
			else this.store.runAsHighlights = 1;
		},
		showKalert(responseObject) {
			this.store.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) this.store.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			this.store.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) this.store.kalert.type = responseObject.type;
			this.store.kalert.shown = true;
			setTimeout(function() {
				this.store.kalert.shown = false;
			}.bind(this), 3000);
		},
		closeKalert() {
			this.store.kalert.shown = false;
		},
		setUser(userob) {
			this.store.user = userob;
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		//old store actions
		showTempDangerAlert(str) {
			let ob = { message: str, alerttype: 'error'	};
			this.showKalert(ob);
			setTimeout(function() {
				this.closeKalert();
			}, 3000);
		},
		setReportType(str) {
			this.store.jobname = '';
			this.store.jobid = 0;
			this.clearAllSelections();
			this.store.reportType = str;
			this.store.activeTab = 'Markets';
			this.store.showGreenHighlights = false;
		},
		//old store getters - converted to functions
		// getSelectionObjects() {
		// 	let s = {};
		// 	s.markets = this.store.markets.filter(item => this.store.selectionIds.markets.includes(item.id));
		// 	let survs = this.store.surveys.filter(item => this.store.selectionIds.surveys.includes(item.id));
		// 	if(this.store.survOldestLeft === 1) {
		// 		survs = survs.sort((a, b) => parseInt(a.reldate) - parseInt(b.reldate));
		// 	}
		// 	else {
		// 		survs = survs.sort((b, a) => parseInt(a.reldate) - parseInt(b.reldate));
		// 	}
		// 	s.surveys = survs;
		//
		// 	let tarr = [];
		// 	for(let i=0; i<this.store.selectionObs.stations.length; i++) {
		// 		let tid = this.store.selectionObs.stations[i].id;
		// 		let mys = this.store.stations.find(item => item.id === tid);
		// 		if(mys !== undefined) tarr.push(mys);
		// 		let mysc = this.store.stationcombos.find(item => item.id === tid);
		// 		if(mysc !== undefined) tarr.push(mysc);
		// 	}
		// 	s.stations = tarr;
		//
		// 	tarr = [];
		// 	for(let i=0; i<this.store.selectionObs.demos.length; i++) {
		// 		let tid = this.store.selectionObs.demos[i].id;
		// 		tarr.push(this.store.demos.find(item => item.id === tid));
		// 	}
		// 	s.demos = tarr;
		//
		// 	tarr = [];
		// 	for(let i=0; i<this.store.selectionObs.statistics.length; i++) {
		// 		let tid = this.store.selectionObs.statistics[i].id;
		// 		tarr.push(this.store.statistics.find(item => item.id === tid));
		// 	}
		// 	s.statistics = tarr;
		//
		// 	let sl = [];
		// 	for(let i=0; i<s.surveys.length; i++) {
		// 		sl.push(s.surveys[i].dataloc);
		// 	}
		// 	s.surveydatalocs = sl;
		// 	return s;
		// },
		getRfTotalSpotsComputed() {
			let nspots = 0;
			for (let i = 0; i < this.store.rfjsonarr.length; i++) { //this is an array of objects
				let myarr = this.store.rfjsonarr[i];
				for (let j = 0; j < myarr.sessionSpots.length; j++) {
					let myob = myarr.sessionSpots[j];
					if (myob.nspots) {
						nspots += parseInt(myob.nspots);
					}
				}
			}
			return nspots;
		},

	}

}