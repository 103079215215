<template>
	<div v-if="pasteinprogress === false && weekid <= this.store.nweeksrf" class="schedwk">
		<div class="schedwk-row">
			<span style="margin-left: 10px;">Week {{weekid}} Schedule</span>
			<div class="td-fas">
				<i class="mdi mdi-content-copy" title="Copy this week's schedule to clipboard" @click="copyWeekToClipboard"></i>
				<i class="mdi mdi-content-paste" title="Paste clipboard week into this week" @click="$emit('pastetonewweek')"></i>
			</div>
		</div>
		<ScheduleWeekStation v-for="stn in this.store.selectionObs.stations" :key="stn.id"
			:weekid="weekid" :stationid="stn.id"
			v-on:json="updateJson($event, stn.id)"
		></ScheduleWeekStation>
	</div>
</template>

<script>
import ScheduleWeekStation from "@/components/rf/ScheduleWeekStation";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ScheduleWeek",
	components: {
		ScheduleWeekStation
	},
	mixins: [globalMixin],
	props: {
		weekid: Number,
		pasteinprogress: Boolean,
	},
	data: function () {
		return {
			store: store,
			myjson: [],
		}
	},
	computed: {

	},
	methods: {
		copyWeekToClipboard() {
			this.store.rfweekclipboard = this.myjson;
		},
		updateJson(jsonitem, stnid) {
			let myob = this.myjson.find(item => item.weekid === this.weekid && stnid === item.station);
			if(myob) { //already exists in array
				let arr = this.myjson.filter(item => stnid !== item.station);
				arr.push(jsonitem);
				this.myjson = arr;
			}
			else {
				this.myjson.push(jsonitem);
			}
		},
		emitjson() {
			this.$emit('updatejson', this.myjson);
		},
	},
	watch: {
		myjson() {
			this.emitjson();
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.schedwk {
	display: inline-block;
}
.schedwk-row {
	background-color: #EFEFEF;
	font-size: 12px;
	height: 26px;
	display: flex;
	align-items: center;
	width: calc(100% - 44px);
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	justify-content: space-between;
}
.td-fas {
	margin-right: 10px;
}
.td-fas i {
	cursor: pointer;
	margin-left: 5px;
}
.td-fas i:hover {
	color: #888;
}
</style>