import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://yourwavelength.com/'; //api call (WL main) url
if (window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
	urlroot = 'https://wavelength.test/';
}

const defaultTableLayout = {table: 'statistics', row: 'stations', col: 'surveys', d4: 'demos', colsplit: ''};

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	user: {
		id: 0,
		email: '',
		token: '',
		isadmin: null,
	},
	isDevServer: isDevServer,
	urlroot: urlroot,
	apiroot: urlroot + 'central/post-freq-vue/', //new post dir
	// calcEngineRoot: urlroot + 'central/post/freq/', //old post dir
	calcEngineRoot: 'https://yourwavelength.com/central/post/freq/', //old post dir
	logosRoot: urlroot + 'central/freq-assets/',
	getparams: {},

	reportType: null, //normal, reachFrequency, allQIDs, SynTSL
	activeTab: null,
	survOldestLeft: 0,
	useWeighting: 1,
	useRawNums: 0,
	marketViewListChooser: 'surveyed',

	markets: [],
	surveys: [],
	stations: [],
	stationcombos: [],
	demos: [],
	statistics: [],
	tvaggmkts: [],
	demofolders: [],
	combofolders: [],

	shortcutmarketTrigger: [],
	forceMenusClose: 0,

	selectionObs: {
		markets: [],
		surveys: [],
		stations: [],
		demos: [],
		statistics: [],
	},

	nweeksrf: 1,
	nweeksmax: 4,
	rfjsonarr: [],
	rfTotalSpots: 0,
	reterrormsg: '',
	rethtml: '',
	retrfproblems: [],
	defaultTableLayout: defaultTableLayout,
	selectedTableLayout: defaultTableLayout,
	toExcel: 0,
	toPowerBi: 0,
	runAsHighlights: 0,
	canSeeSpecial: [1, 176, 80, 3, 174, 2, 977, 1268, 1644], //ian g 977, jen 176, john m 80, rodb 3, 174 beth, 2 paul a, 1268 pasan at sca, 1644 carmen xtra
	adminuids: [1, 176],

	returnedData: [],
	resultMeta: [],
	returnedWarnings: [],
	multiMarketCumeReturn: [],
	selectionsHaveChangedNotYetRun: true,
	showLoader: false,
	showGreenHighlights: false,
	resultTableShownIndex: 0,
	calcIsRunning: false,

	jobname: '',
	jobid: 0,
	jobManageOverlayShown: false,
	batchManageOverlayShown: false,
	combosManageOverlayShown: false,
	chartExplorerOverlayShown: false,
	comboView: 'main', //set to 'edit' for new combo or edit combo
	demosManageOverlayShown: false,
	demoNewOverlayShown: false,
	demoNewData: {userid: -1, survid: 0},

	rfrowclipboard: null,
	rfweekclipboard: null,

	statisticDescrips: [
		{name: 'stnmost', descrip: 'Percentage of listeners to the daypart that selected each station as the “station they listened to most” in the past week within the daypart. <br/><br/>Each listener can only listen to a single station “most” within each daypart. Therefore Station A SLTM + Station B SLTM will always equal a Station A / Station B combo SLTM.'},
		{name: 'cumepct', descrip: 'Percentage of people that tune to each station for at least 8 minutes within the daypart within the past week. Listeners can select multiple stations in line with listening to more than one station per daypart (so adding each station’s individual Cume % can sum to greater than 100%).<br/><br/>' +
				'Cume Reach % and Cume Reach (00s) are equivalent to weekly reach statistics. <br/><br/>' +
				'When Cume statistics are run on station combos (more than one station combined), the returned number is de-duplicated. If Station A receives 30% Cume % and Station B receives 20%, a combo of Station A and Station B will likely be less than 50% as some listeners will listen to both.'},
		{name: 'cume00', descrip: 'Total number of different listeners, in hundreds, who have listened to each station in the daypart for at least 8 minutes in the past week.<br/><br/>' +
				'Cume Reach % and Cume Reach (00s) are equivalent to weekly reach statistics.<br/><br/>' +
				'When Cume statistics are run on station combos (more than one station combined), the returned number is de-duplicated. If Station A receives 30% Cume % and Station B receives 20%, a combo of Station A and Station B will likely be less than 50% as some listeners will listen to both.'},
		{name: 'excl00', descrip: 'Number (in hundreds) of weekly listeners who listen ONLY (exclusively) to each station. Depending on the daypart, station figures may be quite low, with a large percentage of people listening “to multiple” stations. This can be useful when assessing the size of the audience that only can be reached by advertising on each station (eg if you don’t advertise on Station X then you’ll miss 15% of the market). <br/><br/>' +
				'Unlike Cume statistics, Exclusive statistics also include a “do not listen in daypart” percentage/number. The station percentages are based on the total weekly sample, not the daypart sample.'},
		{name: 'exclpct', descrip: 'Percentage of weekly listeners who listen ONLY (exclusively) to each station. Depending on the daypart, station figures may be quite low, with a large percentage of people listening “to multiple” stations. This can be useful when assessing the size of the audience that only can be reached by advertising on each station (eg if you don’t advertise on Station X then you’ll miss 15% of the market). '},
		{name: 'dempot', descrip: 'Total potential audience/population in the market within the chosen demographic'},
		{name: 'samplesize', descrip: 'Sample size of the survey within the demographic'},
	],

	netcolors: [
		{key: 'arn_k', col: '#e50076'},
		{key: 'arn', col: '#e50076'},
		{key: 'arn_p', col: '#EDCA62'},
		{key: 'sca_h', col: '#1bcfc9'},
		{key: 'sca', col: '#1bcfc9'},
		{key: 'sca_m', col: '#fc6402'},
		{key: 'abc_loc', col: '#000000'},
		{key: 'abc_j', col: '#f33e2a'},
		{key: 'nine', col: '#092e52'},
		{key: 'nova', col: '#eb1c24'},
		{key: 'nova_s', col: '#005094'},
	],
	defaultColors: ['#003f5c','#42d534','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600'],

	// //old store getters
	// rfTotalSpotsComputed: computed(function() {
	// 	let nspots = 0;
	// 	for (let i = 0; i < this.store.rfjsonarr.length; i++) { //this is an array of objects
	// 		let myarr = this.store.rfjsonarr[i];
	// 		for (let j = 0; j < myarr.sessionSpots.length; j++) {
	// 			let myob = myarr.sessionSpots[j];
	// 			if (myob.nspots) {
	// 				nspots += parseInt(myob.nspots);
	// 			}
	// 		}
	// 	}
	// 	return nspots;
	// }.bind(this)),

})