<template>
	<div>
		<div v-if="type === 'own'">
			<OwnershipLogo v-for="o in obj.ownership.owns" :key="o" :type="type" :str="o" :wid="width" :ht="height"></OwnershipLogo>
		</div>
		<div v-if="type === 'reps'">
			<OwnershipLogo v-for="o in obj.ownership.reps" :key="o" :type="type" :str="o" :wid="width" :ht="height"></OwnershipLogo>
		</div>
	</div>
</template>

<script>
import OwnershipLogo from "@/components/OwnershipLogo";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "OwnershipCell",
	components: {
		OwnershipLogo
	},
	mixins: [globalMixin],
	props: {
		obj: Object,
		type: String,
		wid: String,
		ht: String,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		width() {
			if(this.wid) return this.wid;
			return null;
		},
		height() {
			if(this.ht) return this.ht;
			return null;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>