<template>
	<div class="popover-item">
		<div class="popover-header">
			<h3>
				{{title}}
				<i class="mdi mdi-close" @click="$emit('close')" style="cursor: pointer; margin-left: 4px; font-size: 18px;"></i>
			</h3>

		</div>
		<div class="popover-content">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "PopoverItem",
	components: {},
	props: {
		title: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.popover-item {
	position: absolute;
	z-index: 994;
	background: #FFF;
	border: 1px solid #CCC;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	max-width: 400px;
}
.popover-header {
	padding: 10px 20px;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: bold;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header h3 {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	align-items: center;
}
.popover-content {
	background-color: #FAFAFA;
	padding: 20px;
}
</style>