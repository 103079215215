<template>
	<div class="view-holder">
		<div v-if="store.selectionObs.markets.length === 0">
			<p>Please choose at least one market first</p>
		</div>
		<div class="chooser" v-else>
			<div class="left-side">
				<div style="display: flex; justify-content: space-between; margin-bottom: 10px; width: 95%">
					<div @click="viewStationsOrCombos = 'stations'" class="stationOrComboTab" :class="{activet:viewStationsOrCombos==='stations'}">Stations</div>
					<div @click="viewStationsOrCombos = 'combos'" class="stationOrComboTab" :class="{activet:viewStationsOrCombos==='combos'}">Combos</div>
				</div>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter..." v-model="filterText" class="filterbox" />
				</div>
				<div class="checkbox-list-hold">
					<div v-if="viewStationsOrCombos === 'stations'">
						<p class="boldme stngrouphead">Stations</p>
						<StationMarketGroup v-for="m in store.selectionObs.markets" :key="m.id" :mktid="m.id" :filtertext="filterText"
								:hiddenmarketids="hiddenMarketIds" v-on:toggleviewed="toggleviewed($event)"
						></StationMarketGroup>
					</div>
					<div v-if="reportType !== 'reachFrequency' && viewStationsOrCombos === 'combos'">
						<div v-if="reportType !== 'reachFrequency'">
							<p class="boldme stngrouphead">Combos - Networks (Supplier)</p>
							<div v-for="stn in filteredSupplierCombos" :key="stn.id" class="checklist-item">
								<div class="ch-and-title" @click="toggleStationInSelection(stn)">
									<span class="toggler">
										<i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i>
									</span>
									<span class="label" :id="'stnpop'+stn.id">
										{{stn.name}}
									</span>
								</div>
								<InfoIcon v-on:clicked="showComboPopoverDo(stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
							</div>
						</div>

						<div v-if="reportType !== 'reachFrequency'">
							<p class="boldme stngrouphead">Combos - User Defined & Other</p>
							<ComboViewGroup :combos="filteredUserCombos" v-on:togglecombo="toggleStationInSelection($event)" v-on:showpopover="showComboPopoverDo($event)"></ComboViewGroup>

						</div>
					</div>
				</div>
			</div>

			<div class="right-side sortable-list">
				<draggable v-model="mylist" item-key="index">
					<template #item="{index}">
						<SortableInternal type="station" :objid="mylist[index].id" v-on:remove="toggleStationInSelection(mylist[index])"></SortableInternal>
					</template>
				</draggable>
			</div>

			<div v-if="showComboPopover" class="loader-overlay">
				<div class="market-summary-view">
					<StationComboPopover :stnob="stnComboOb" v-on:close="showComboPopover = false"></StationComboPopover>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import StationMarketGroup from "@/components/station-selections/StationMarketGroup";
import SortableInternal from "@/components/SortableInternal";
import StationComboPopover from "@/components/station-selections/StationComboPopover";
import InfoIcon from "@/components/InfoIcon";
import ComboViewGroup from "@/components/combos/ComboViewGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Stations",
	components: {
		ComboViewGroup,
		InfoIcon,
		StationComboPopover,
		SortableInternal,
		StationMarketGroup,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',
			showComboPopover: false,
			stnComboOb: null,
			hiddenMarketIds: [],
			viewStationsOrCombos: 'stations',
		}
	},
	computed: {
		mylist: {
			get() {
				return this.store.selectionObs.stations;
			},
			set(value) {
				this.store.selectionObs.stations = value;
			}
		},
		reportType() {
			return this.store.reportType;
		},
		filteredSupplierCombos() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === 1);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredUserCombos() {
			let mycombos = this.store.stationcombos.filter(item => (item.mktid === 0 || this.store.selectionObs.markets.some(elem => elem.id === item.mktid)) && item.isSystem === 0);
			if(this.filterText.length === 0) return mycombos;
			else { //either already selected or matching text
				return mycombos.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		showComboPopoverDo(stncomboOb) {
			this.stnComboOb = stncomboOb;
			this.showComboPopover = true;
		},
		toggleviewed(mktid) {
			if(this.hiddenMarketIds.includes(mktid)) {
				let x = this.hiddenMarketIds.filter(item => item !== mktid);
				this.hiddenMarketIds = x;
			}
			else {
				this.hiddenMarketIds.push(mktid);
			}
		},
	},
	watch: {

	},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}
.chooser {
	display: flex;
}
.left-side, .right-side {
	height: calc(100vh - 200px);
	overflow-y: auto;
}
.left-side {
	width: 500px;
}
.right-side {
	margin-left: 50px;
	min-height: 500px;
	width: 500px;
	padding: 5px 0;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	box-shadow: 0 0 6px 1px #e8e8e8 inset;
}
.market-summary-view {
	width: 600px;
	height: 80%;
	position: fixed;
	top: 5%;
	margin: 0 auto;
}
.stngrouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
.stationOrComboTab {
	border: 1px solid #BBB;
	width: 50%;
	padding: 10px 0;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: bold;
	border-radius: 10px 10px 0 0;
}
.stationOrComboTab.activet {
	background-color: #DDD;
}
.stationOrComboTab:not(.activet):hover {
	background: #EFEFEF;
}
.filterbox {
	width: 95%;
}
</style>