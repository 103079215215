<template>
	<div class="top-menu-group">
		<div class="top-menu-label" @click="toggleDropdown">
			{{label}}
		</div>
		<div v-if="showDropdown" class="top-menu-dropdowns" @click.stop="showDropdown = false">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "TopMenuGroup",
	components: {},
	mixins: [globalMixin],
	props: {
		label: String,
		hasdropdown: Boolean,
	},
	data: function () {
		return {
			showDropdown: false,
			store: store,
		}
	},
	computed: {
		forceMenusClose() {
			return this.store.forceMenusClose;
		}
	},
	methods: {
		toggleDropdown() {
			let showThisMenu = false
			if(this.showDropdown === false) showThisMenu = true
			this.closeAllMenus()
			setTimeout(function() {
				if(showThisMenu === true) this.showDropdown = true
				else this.showDropdown = false
			}.bind(this), 30)
		},
	},
	watch: {
		forceMenusClose() {
			this.showDropdown = false;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.top-menu-group {
	cursor: pointer;
}
.top-menu-label {
	color: #FFF;
	font-size: 12px;
	font-family: 'Roboto';
	font-weight: bold;
	height: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 20px;
}
.top-menu-label:hover {
	background-color: #454545;
}
.top-menu-dropdowns {
	background-color: #000;
	height: auto;
	top: 35px;
	position: absolute;
	width: 200px;
}
</style>