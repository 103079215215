<template>
	<div class="view-holder">
		<div v-if="reportType === 'reachFrequency' || store.selectionsHaveChangedNotYetRun === false">
			<div v-if="store.reterrormsg.length > 0">{{store.reterrormsg}}</div>
			<div v-else-if="store.rethtml.length > 0">
				<div v-html="store.rethtml"></div>
			</div>
			<div v-else>

				<div v-if="reportType === 'reachFrequency' && store.retrfproblems.length > 0" style="margin-bottom: 10px;">
					<p style="font-size: 13px; " v-for="(prob, index) in store.retrfproblems" :key="index">{{prob}}</p>
				</div>

				<div v-if="totalNumTables > 0" class="result-table-nav">
					<button class="resnavbtn" @click="navToPrevTable">&lt;</button>
					<span class="tsp"> Table {{activeTableNum}} of {{totalNumTables}} </span>
					<button class="resnavbtn" @click="navToNextTable">&gt;</button>
				</div>

				<div v-if="reportType === 'normal'">
					<div v-if="!this.colsplit || this.colsplit === ''">
						<div v-for="(tblloopitem, index) in tableLayoutLoopItems" :key="index">
							<ResultTable v-if="index === resultTableShownIndex" :ind="index" :objin="tblloopitem" :class="{hidden : index !== resultTableShownIndex}"></ResultTable>
						</div>
					</div>
					<div v-if="this.colsplit.length > 0">
						<div v-for="(tblloopitem, index) in tableLayoutLoopItems" :key="index">
							<ResultTableWithColsplit v-if="index === resultTableShownIndex" :objin="tblloopitem" :class="{hidden : index !== resultTableShownIndex}"></ResultTableWithColsplit>
						</div>
					</div>
				</div>
				<div v-else>
					<div v-for="(item, index) in store.returnedData" :key="index">
						<ResultTableBasic :objin="item" :class="{hidden : index !== resultTableShownIndex}"></ResultTableBasic>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<p v-if="selectionsAreAllValid === false">Make your selections then click Process to fetch results.</p>
			<p v-if="selectionsAreAllValid === true">Click the Process button above to fetch results.</p>
		</div>
	</div>
</template>

<script>
import ResultTable from "@/components/result-tables/ResultTable";
import ResultTableWithColsplit from "@/components/result-tables/ResultTableWithColsplit";
import ResultTableBasic from "@/components/result-tables/ResultTableBasic";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Results",
	components: {
		ResultTableBasic,
		ResultTableWithColsplit,
		ResultTable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		resultTableShownIndex: {
			get() {
				return this.store.resultTableShownIndex;
			},
			set(val) {
				this.store.resultTableShownIndex = val;
			}
		},
		selectedTableLayout() {
			return this.store.selectedTableLayout;
		},
		activeTableNum() {
			return this.resultTableShownIndex + 1;
		},
		totalNumTables() {
			if(this.reportType === 'normal') {
				return this.tableLayoutLoopItems.length;
			}
			else {
				return this.store.returnedData.length;
			}
		},
		rethtml() {
			return this.store.rethtml;
		},
		selectionsAreAllValid() {
			if(this.store.selectionObs.markets.length === 0) return false;
			if(this.store.selectionObs.surveys.length === 0) return false;
			if(this.reportType === 'normal') {
				if(this.store.selectionObs.statistics.length === 0) return false;
			}
			else if(this.reportType === 'reachFrequency') {
				let rfTotalSpotsComputed = this.getRfTotalSpotsComputed()
				if(rfTotalSpotsComputed <= 0) return false;
			}
			let needsStations = ['normal','reachFrequency'];
			if(needsStations.includes(this.reportType)) {
				if(this.store.selectionObs.stations.length === 0) return false;
			}
			let needsDemos = ['normal','reachFrequency','allQIDs'];
			if(needsDemos.includes(this.reportType)) {
				if(this.store.selectionObs.demos.length === 0) return false;
			}
			return true;
		},
		reportType() {
			return this.store.reportType;
		},
		tbl() {
			return this.store.selectedTableLayout.table;
		},
		tblobs() {
			return this.getobs(this.tbl);
		},
		row() {
			return this.store.selectedTableLayout.row;
		},
		rowobs() {
			return this.getobs(this.row);
		},
		col() {
			return this.store.selectedTableLayout.col;
		},
		colobs() {
			return this.getobs(this.col);
		},
		colsplit() {
			return this.store.selectedTableLayout.colsplit;
		},
		colsplitobs() {
			if(this.store.selectedTableLayout.colsplit.length > 0) {
				return this.getobs(this.colsplit);
			}
			return false;
		},
		d4() {
			return this.store.selectedTableLayout.d4;
		},
		d4obs() {
			if(this.store.selectedTableLayout.d4.length > 0) {
				return this.getobs(this.d4);
			}
			return false;
		},
		retdata() {
			return this.store.returnedData;
		},
		tableLayoutLoopItems() {
			let out = [];
			if (this.colsplit === '') {
				for (let t=0; t<this.tblobs.length; t++) {
					let mytblob = this.tblobs[t];
					for(let d=0; d<this.d4obs.length; d++) {
						let myd4ob = this.d4obs[d];
						let tblitem = {};
						tblitem[this.tbl] = mytblob;
						tblitem[this.d4] = myd4ob;
						let allcols = [];
						for(let c=0; c<this.colobs.length; c++) {
							allcols.push(this.colobs[c]);
						}
						tblitem[this.col] = allcols;
						let allrows = [];
						for(let r=0; r<this.rowobs.length; r++) {
							allrows.push(this.rowobs[r]);
						}
						tblitem[this.row] = allrows;
						tblitem.table = this.tbl;
						tblitem.d4 = this.d4;
						tblitem.col = this.col;
						tblitem.row = this.row;
						tblitem.colsplit = this.colsplit;
						out.push(tblitem);
					}
				}
			}
			else if(this.colsplit.length > 0) { //colsplit is always 'surveys', row is always 'stations', and d4 always blank
				for (let t=0; t<this.tblobs.length; t++) {
					let mytblob = this.tblobs[t];
					let tblitem = {};
					tblitem[this.tbl] = mytblob;
					let allcols = [];
					for(let c=0; c<this.colobs.length; c++) {
						allcols.push(this.colobs[c]);
					}
					tblitem[this.col] = allcols;
					let allrows = [];
					for(let r=0; r<this.rowobs.length; r++) {
						allrows.push(this.rowobs[r]);
					}
					tblitem[this.row] = allrows;
					let allcolsplits = [];
					for(let r=0; r<this.colsplitobs.length; r++) {
						allcolsplits.push(this.colsplitobs[r]);
					}
					tblitem[this.colsplit] = allcolsplits;
					tblitem.table = this.tbl;
					tblitem.d4 = this.d4;
					tblitem.col = this.col;
					tblitem.row = this.row;
					tblitem.colsplit = this.colsplit;
					out.push(tblitem);
				}
			}
			return out;
		},
	},
	methods: {
		getobs(obtype) {
			let sels = this.store.selectionObs
			if(obtype === 'statistics') return sels.statistics;
			else if(obtype === 'demos') return sels.demos;
			else if(obtype === 'stations') return sels.stations;
			else if(obtype === 'surveys') return sels.surveys;
		},
		navToNextTable() {
			if(this.resultTableShownIndex < (this.totalNumTables - 1)) {
				this.resultTableShownIndex++;
			}
		},
		navToPrevTable() {
			if(this.resultTableShownIndex > 0) {
				this.resultTableShownIndex--;
			}
		},
	},
	watch: {
		selectedTableLayout() {
			this.resultTableShownIndex = 0;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}
.tsp {
	display: inline-block;
	margin-top: 2px;
}
</style>