<template>
	<div class="tabs-section">
		<div class="tabs-hold">
			<div class="tabs-group">
				<Tab title="Markets"></Tab>
				<Tab title="Surveys"></Tab>
				<Tab v-if="showStationsTab" title="Stations"></Tab>
				<Tab v-if="showDemosTab" title="Demos"></Tab>
				<Tab v-if="showStatisticsTab" title="Statistics"></Tab>
				<Tab v-if="store.reportType === 'reachFrequency'" title="Schedule"></Tab>
				<Tab title="Results"></Tab>
			</div>
		</div>
		<div class="below-tabs">
			<div v-if="activeTab === 'Markets'" class="flex">
				<div class="shortcut-group">
					<p class="shortcut-name">State</p>
					<div class="shortcuts">
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'all')">All/Clear</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'NSW')">NSW</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'VIC')">VIC</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'QLD')">QLD</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'SA')">SA</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'WA')">WA</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('state', 'TAS')">TAS</span>
					</div>
				</div>
				<div class="shortcut-group">
					<p class="shortcut-name">TV Agg Mkts</p>
					<div class="shortcuts">
						<span class="freq-shortcut" @click="shortcutMarketsTrig('tvagg', 11)">QLD</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('tvagg', 9)">Nth NSW</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('tvagg', 12)">Sth NSW</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('tvagg', 15)">Vic</span>
					</div>
				</div>
				<div class="shortcut-group">
					<p class="shortcut-name">Owner</p>
					<div class="shortcuts">
						<span class="freq-shortcut" @click="shortcutMarketsTrig('owner', 'sca')">SCA</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('owner', 'arn')">ARN</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('owner', 'ace')">Ace</span>
					</div>
				</div>
				<div class="shortcut-group">
					<p class="shortcut-name">Rep Company</p>
					<div class="shortcuts">
						<span class="freq-shortcut" @click="shortcutMarketsTrig('rep', 'sca')">SCA</span>
						<span class="freq-shortcut" @click="shortcutMarketsTrig('rep', 'arn')">ARN</span>					</div>
				</div>
			</div>
			<div v-if="activeTab === 'Surveys'" class="flex" style="height: 100%;">
				<span class="freq-shortcut" @click="shortcutSurveys('latest-all')">Latest</span>
				<span v-if="store.canSeeSpecial.includes(store.user.id)" class="freq-shortcut" @click="shortcutSurveys('latest-notrk')">Latest (ignore tracking)</span>
				<input type="checkbox" v-model="store.survOldestLeft" :true-value="1" :false-value="0" style="margin-left: 20px;" id="oldleft" />
				<label for="oldleft" style="margin: 0 15px 2px 5px; font-size: 12px;">Display results as oldest=left to newest=right</label>
			</div>
			<div v-if="activeTab === 'Stations'" class="flex" style="height: 100%;">
				<span class="freq-shortcut" @click="shortcutStations('clear')">Clear</span>
				<span class="freq-shortcut" @click="shortcutStations('all')">All</span>
				<span class="freq-shortcut" @click="shortcutStations('public')">Public</span>
			</div>
			<div v-if="activeTab === 'Demos'" class="flex" style="height: 100%;">
				<span class="freq-shortcut" @click="shortcutDemos('clear')">Clear</span>
				<span class="freq-shortcut" @click="shortcutDemos('occ')">Occupations</span>
			</div>
			<div v-if="activeTab === 'Statistics'" class="flex" style="height: 100%;">
				<span class="freq-shortcut" @click="shortcutStatistics('clear')">Clear</span>
				<span class="freq-shortcut" @click="shortcutStatistics('statisticMost')">Most</span>
				<span class="freq-shortcut" @click="shortcutStatistics('statisticCHund')">C00s</span>
				<span class="freq-shortcut" @click="shortcutStatistics('statisticCPct')">C%</span>
			</div>
			<div v-if="activeTab === 'Schedule'" class="flex" style="height: 100%;">
				<span style="margin-right: 15px;">Select desired Spot Schedule for R&F run</span>
				<div class="processbtn" @click="getResults">Process</div>
			</div>
			<div v-if="activeTab === 'Results'" class="flex">
				<div>
					<select v-model="store.selectedTableLayout" style="padding: 5px; border: 1px solid #CCC;">
						<option v-for="tl in tableLayoutSelections" :key="tl.title" :value="tl">
							{{generateOptionTitle(tl)}}
						</option>
					</select>
				</div>
				<div class="btn-holder">
					<div class="processbtn" @click="getResults">Process</div>
					<div class="exports">
						<div @click="getExcelExport"><img :src="store.logosRoot+'excel_icon.png'" alt="Excel" /></div>
<!--						<div><img :src="store.logosRoot+'graph_icon.png'" alt="Chart" /></div>-->
						<div v-if="showPowerBi" @click="getPowerBiExport"><img :src="store.logosRoot+'power-bi-logo.png'" alt="Chart" /></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Tab from "@/components/Tab";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TopTabBar",
	components: {
		Tab
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

			tableLayoutSelections: [
				{table: 'statistics', row: 'stations', col: 'surveys', d4: 'demos', colsplit: ''},
				{table: 'surveys', row: 'stations', col: 'statistics', d4: 'demos', colsplit: ''},
				{table: 'surveys', row: 'stations', col: 'demos', d4: 'statistics', colsplit: ''},
				{table: 'statistics', row: 'stations', col: 'demos', d4: 'surveys', colsplit: ''},
				{table: 'stations', row: 'demos', col: 'statistics', d4: 'surveys', colsplit: ''},
				{table: 'stations', row: 'statistics', col: 'demos', d4: 'surveys', colsplit: ''},
				{table: 'demos', row: 'stations', col: 'surveys', d4: 'statistics', colsplit: ''},
				{table: 'demos', row: 'stations', col: 'statistics', d4: 'surveys', colsplit: ''},
				{table: 'demos', row: 'statistics', col: 'stations', d4: 'surveys', colsplit: ''},
				{table: 'statistics', row: 'stations', col: 'demos', d4: '', colsplit: 'surveys'},
				{table: 'demos', row: 'stations', col: 'statistics', d4: '', colsplit: 'surveys'},
			],
		}
	},
	computed: {
		activeTab() {
			return this.store.activeTab;
		},
		rpt() {
			return this.store.reportType;
		},
		showStatisticsTab() {
			if(this.rpt === 'normal') return true;
			return false;
		},
		showStationsTab() {
			let shows = ['normal','reachFrequency'];
			if(shows.includes(this.rpt)) return true;
			return false;
		},
		showDemosTab() {
			let shows = ['normal','reachFrequency','allQIDs'];
			if(shows.includes(this.rpt)) return true;
			return false;
		},
		//normal, reachFrequency, allQIDs, StnDemoCalcs, pctStnCume, stnCumeSharing, stnMostByCumeStn, ebbNFlow
		showPowerBi() {
			if(this.rpt !== 'normal') return false;
			if(this.store.canSeeSpecial.includes(this.store.user.id)) return true;
			else if(this.store.user.email.toLowerCase().includes('abc.net.au')) return true; //gill 534, sru 1521
			return false;
		},
	},
	methods: {
		generateOptionTitle(opt) {
			let out = 'Tbl: '+this.ucfirst(opt.table)+", Rows: "+this.ucfirst(opt.row);
			if(opt.colsplit.length === 0) out += ", Col: "+this.ucfirst(opt.col);
			else out += ", Col: "+ this.ucfirst(opt.col)+" x "+this.ucfirst(opt.colsplit);
			return out;
		},
		getExcelExport() {
			this.store.toExcel = 1;
			this.getResults();
		},
		getPowerBiExport() {
			this.store.toPowerBi = 1;
			this.getResults();
		},
		shortcutSurveys(val) {
			let sels = [];
			for(let m=0; m<this.store.selectionObs.markets.length; m++) {
				let mymktid = this.store.selectionObs.markets[m].id;
				let survs = this.store.surveys.filter(item => item.mktid === mymktid); //latest-all
				if (val === 'latest-notrk') {
					survs = this.store.surveys.filter(item => item.mktid === mymktid && item.is_cra_gs === 1);
				}
				if (survs.length > 0) {
					let mktsurvsnewest = survs.sort((b, a) => parseInt(a.reldate) - parseInt(b.reldate));
					sels.push(mktsurvsnewest[0]);
				}
			}
			this.store.selectionObs.surveys = sels;
		},
		shortcutStations(val) {
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			else {
				for(let mymkt of this.store.selectionObs.markets) {
					if(mymkt) {
						let mystnids = mymkt.allStidsInOrder;
						if(val === 'public') {
							mystnids = mymkt.publicStidsInOrder;
						}
						for(let i=0; i<mystnids.length; i++) {
							let mystnob = this.store.stations.find(item => item.id === mystnids[i]);
							if(mystnob) {
								if(mystnob.community === 0 || (mystnob.community === 1 && this.store.user.viewCommunities === 1)) {
									sels.push(mystnob);
								}
							}
						}
					}
				}
			}
			this.store.selectionObs.stations = sels;
		},
		shortcutDemos(val) { //clear, occ
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			else {
				for(var i=1102; i<=1129; i++) {
					sels.push(this.store.demos.find(item => item.id === i));
				}
			}
			this.store.selectionObs.demos = sels;
		},
		shortcutStatistics(val) {
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			else if(val === "statisticMost") {
				sels = [2,4,5,6,7,8,10,9];
			}
			else if(val === "statisticCHund") {
				sels = [11,13,15,17,19,21,23,25];
			}
			else if(val === "statisticCPct") {
				sels = [1,12,14,16,18,20,22,24];
			}
			let arr = []
			for(let s of sels) {
				arr.push(this.store.statistics.find(item => item.id === s))
			}
			this.store.selectionObs.statistics = arr;
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tabs-section {
	position: fixed;
	width: 100%;
	top: 40px;
}
.tabs-hold {
	height: 50px;
	background-color: #CF432C;
	display: flex;
	align-items: flex-end;
	padding-left: 12px;
	position: relative;
}
.tabs-group {
	display: flex;
}
.below-tabs {
	background-color: #EFEFEF;
	background: linear-gradient(to bottom, #efefef 0%,#DDDDDD 100%);
	border-bottom: 1px solid #BBB;
	height: 60px;
	margin-bottom: 15px;
	padding: 10px 20px 10px;
	width: 100%;
}
.flex {
	display: flex;
	align-items: center;
}
.btn-holder {
	margin-left: 30px;
	display: flex;
	align-items: center;
}
.processbtn {
	cursor: pointer;
	text-transform: uppercase;
	padding: 13px 25px;
	font-size: 13px;
	margin: 0 30px 0 20px;
	border: none;
	background-color: #111;
	color: #FFF;
}
.processbtn:hover {
	background-color: #222;
	background-image: none;
	color: #F04E23;
}
.exports {
	display: flex;
}
.exports img {
	height: 40px;
	width: 40px;
	margin-right: 5px;
	cursor: pointer;
}
.shortcut-group {
	margin-left: 15px;
}
.shortcut-name {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
	padding-bottom: 8px;
}
</style>