<template>
	<div class="view-holder">
		<div class="checkbox-list-hold">
			<div v-for="sv in filteredSurveys" :key="sv.id" class="checklist-item">
				<div class="ch-and-title" @click="checkSurveyAccessThenToggle(sv)">
					<span class="toggler" v-if="sv.userCanViewFull === true">
						<i v-if="store.selectionObs.surveys.some(item => item.id === sv.id)" class="mdi mdi-check-bold"></i>
					</span>
					<span class="toggler" style="border: 1px solid #DDD; background-color: #DDD" v-else></span>
					<span class="label">{{sv.name}}</span>
				</div>
				<span class="sswarn" v-if="reportType === 'reachFrequency' && sv.quota < 600">Sample size too small for R&F</span>
				<span class="sswarn" v-if="sv.userCanViewFull !== true" title="Your account will not be able to run results on this survey due to data access limitations.">No access</span>
				<InfoIcon addclasses="small" :id="'suid'+sv.id" @click="popoverClicked('suid'+sv.id)"></InfoIcon>
				<PopoverItem v-if="popoverIdShown === 'suid'+sv.id" :title="sv.name" v-on:close="hidePopovers">
					<template v-slot:content>
						<p><span class="bold">Released: </span>{{sv.relDateArr.day}} {{sv.relDateArr.monthnice}} {{sv.relDateArr.year}}</p>
						<p v-if="sv.quota > 0"><span class="bold">Sample: </span> {{sv.quota}}</p>
					</template>
				</PopoverItem>
			</div>
			<div v-if="store.selectionObs.markets.length === 0">
				<p>Please choose at least one market first</p>
			</div>
		</div>
	</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon";
import PopoverItem from "@/components/PopoverItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Surveys",
	components: {
		PopoverItem,
		InfoIcon
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			popoverIdShown: null,
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
		filteredSurveys() {
			return this.store.surveys.filter(item => this.store.selectionObs.markets.some(elem => elem.id === item.mktid));
		},
	},
	methods: {
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		checkSurveyAccessThenToggle(svob) {
			if(svob.userCanViewFull === true) {
				this.toggleSurveyInSelection(svob)
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px;
}
.checkbox-list-hold {
	max-width: 500px;
}
.sswarn {
	font-size: 11px;
	color: #D88;
	font-style: italic;
	margin-left: 5px;
}
</style>