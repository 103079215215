<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Jobs List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter jobs..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.jobManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides">

				<div v-if="fetching">Loading...</div>

				<div v-else>
					<div v-for="j in filteredJobs" :key="j.id" class="jli">
						<div>
							<span class="jname">{{j.name}}</span><span class="jup"> (updated {{j.tstamp}})</span>
						</div>
						<div class="" style="display: flex; justify-content: space-between; width: 90px;">
							<span class="jop" @click="openJob(j.id)" title="Open job">OPEN</span>
							<span class="jdel" @click="delJob(j.id)" title="Delete job">DELETE</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "JobsManage",
	components: {},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			jobslist: [],
			filterText: '',
		}
	},
	computed: {
		filteredJobs() {
			if(this.filterText.length === 0) {
				return this.jobslist;
			}
			else { //either already selected or matching text
				return this.jobslist.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		fetchJobList() {
			if(this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "jobs-get-list",
					JSON.stringify({
						userob: self.store.user,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.jobs) { //handle returned objects for lists
						self.jobslist = ret.jobs;
					}
					self.fetching = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		openJob(jid) {
			let myjob = this.jobslist.find(item => item.id === jid);
			this.store.selectionObs = {
				markets: this.getObjectsFromArrayById(this.store.markets, myjob.marketidArr),
				surveys: this.getObjectsFromArrayById(this.store.surveys, myjob.survidArr),
				stations: this.getStationObjectsFromArrayById(myjob.stationidArr),
				demos: this.getObjectsFromArrayById(this.store.demos, myjob.demoidArr),
				statistics: this.getObjectsFromArrayById(this.store.statistics, myjob.statisticsidArr),
			};
			this.store.survOldestLeft = myjob.survOldestLeft;
			this.store.jobname = myjob.name;
			this.store.jobid = myjob.id;
			this.store.reportType = myjob.reporttype;
			//layout
			if(myjob.tableLayoutStr.length > 0) {
				this.store.selectedTableLayout = this.convertTableLayoutStringToOb(myjob.tableLayoutStr);
			}
			else { //default
				this.store.selectedTableLayout = this.store.defaultTableLayout;
			}
			this.store.rfjson = [];
			if(myjob.rfjson && myjob.rfjson.length > 0) {
				this.store.rfjsonarr = myjob.rfjson;
			}
			this.store.showGreenHighlights = false;
			this.store.activeTab = 'Results';
			this.store.jobManageOverlayShown = false;
		},
		delJob(jid) {
			let c = window.confirm("Are you sure you wish to delete this job?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "job-delete",
					JSON.stringify({
						userob: self.store.user,
						jid: jid,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
						if(ret.resp.jobid > 0 && self.store.jobid === ret.resp.jobid) { //current active job has been deleted, reset window var
							self.store.jobid = 0;
						}
					}
					self.fetching = false;
					self.fetchJobList(); //refresh the job list
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {},
	mounted() {
		this.fetchJobList();
	}
}
</script>

<style scoped>

</style>