<template>
	<div class="menuItem">
		<router-link v-if="routerlink && routerlink.length > 0" :to="routerlink">
			<span>{{label}}</span>
		</router-link>
		<a v-else-if="extlink && extlink.length > 0" :target="extTargBlank" :href="extlink"><span>{{label}}</span></a>
		<span v-else>{{label}}</span>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TopMenuItem",
	components: {},
	mixins: [globalMixin],
	props: {
		label: String,
		routerlink: String,
		extlink: String,
		targblank: Boolean,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		extTargBlank() { //by default open in blank target/new tab unless overridden by incoming prop
			if(this.targblank === false) return '_self';
			return '_blank';
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.menuItem {
	text-decoration: none;
	color: #FFF;
	transition: background 0.3s ease;
	cursor: pointer;
}
.menuItem a {
	color: #FFF;
	text-decoration: none;

}
.menuItem:hover {
	background-color: #454545;
	color: #FFF;
	text-shadow: none;
}
.menuItem span {
	display: block;
	padding: 12px 20px;
}
</style>