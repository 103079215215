<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">

			<div>

				<div id="logo-holder">
					<a href="https://regionalratings.com.au">
						<img src="@/assets/frequency-logo.png" alt="Logo" />
					</a>
				</div>
				<div id="form-wrapper">

					<div style="height: 20px; margin-bottom: 20px;">
						<p style="">Your IP address is: {{userIp}}</p>
					</div>

					<div class="form-input-group">
						<input type='email' v-model="email" placeholder="Email Address" />
						<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
					</div>
					<div v-if="showingForgetPwd === false" class="form-input-group" style="margin-bottom: 0px;">
						<input type='password' v-model="pass" placeholder="Password/Token" />
					</div>

					<br/><br/>
					<button v-if="showingForgetPwd === false" class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
						<span>Submit</span>
						<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
					</button>

					<button v-if="showingForgetPwd === true" block class="login-button" v-on:click="forgetPwd" v-bind:class="{'button-is-working':(form1working === true)}">
						<span>Resend Password</span>
						<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
					</button>

					<p v-if="showingForgetPwd === false" @click="showingForgetPwd = true" class="text-link">I've lost my password</p>
					<p v-if="showingForgetPwd" @click="showingForgetPwd = false" class="text-link">Cancel</p>

					<p style="font-size: 11px; padding: 15px 0;">If you work at a media buying agency and don't have a login account, please note the IP address shown above and email your SCA or TRSN contact. </p>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'LoginPage',
	components: {
		WorkingButtonInsert
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login
			userIp: null,

			showingForgetPwd: false,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,

			hasLoggedOut: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		urlroot() {
			return this.store.urlroot;
		},
		apiroot() {
			return this.store.apiroot;
		},
	},
	methods: {
		getUserIp() {
			let self = this;
			axios.get(
				this.apiroot + "get-user-ip").then(function(response) {
				var ret = response.data; //response.data is returned info
				if(ret.userIp) self.userIp = ret.userIp;
				if(ret.rruser) { //if this comes back we have logged in successfully
					self.setUser(ret.rruser); //as this will be fake agency email address, unlike normal login, do not auto store this in localStorage (rely on IP each time)
					self.$router.push("/");
				}
				else { //auto IP login failed - check GET and localStorage for credentials
					if(self.store.getparams.email && self.store.getparams.email.length > 0 && self.store.getparams.token && self.store.getparams.token.length > 0) {
						self.email = self.store.getparams.email;
						self.pass = self.store.getparams.token;
						if(self.hasLoggedOut === false) {
							setTimeout(function() { //wait for Chrome etc to screw around with autofill
								self.submitForm();
							}, 1000);
						}
					}
					else { //try from localStorage
						let localemail = window.localStorage.getItem('useremail');
						let localtoken = window.localStorage.getItem('usertoken');
						if(self.hasLoggedOut === false && localemail && localemail.length > 0 && localemail !== 'null' && localtoken && localtoken.length > 0) {
							setTimeout(function() { //wait for Chrome etc to screw around with autofill
								self.email = localemail;
								self.pass = localtoken;
								self.submitForm();
							}, 1000);
						}
					}
				}
			}).catch(function(error) {
				console.log(error);
			});
		},
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.pass.length < 3) return false;
				this.form1working = true; //make button working class after pulse effect is finished
				var self = this;
				axios.post(
					this.apiroot + "do-frequency-login",
					JSON.stringify({
						email: self.email,
						pass: self.pass,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					if(ret.rruser) { //if this comes back we have logged in successfully
						self.setUser(ret.rruser);
						window.localStorage.setItem('useremail', ret.rruser.email);
						window.localStorage.setItem('usertoken', ret.rruser.token);
						self.$router.push("/");
					}
					if(ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret.errob);
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		forgetPwd() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				var self = this;
				axios.post(
					this.apiroot + "resend-password",
					JSON.stringify({
						email: self.email,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					self.form1working = false;
					if(ret.error === 1) {
						self.showKalert(ret.errob);
					}
					else if(ret.success === 1) {
						self.showKalert(ret.successob);
						self.showingForgetPwd = false;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Frequency Login";
		if(this.store.sesscheckcomplete && this.store.user.id > 0) {
			this.$router.push("/");
		}
	},
	created() {
		//GET params are stored in store.getparams from App.vue already but grab the manual logged out one in case
		let urlparams = this.$route.query;
		this.hasLoggedOut = false;
		if(urlparams.loggedout && parseInt(urlparams.loggedout) === 1) {
			this.hasLoggedOut = true;
		}
		this.getUserIp();
	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
.bgpage {
	background-image: url('~@/assets/feature-background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
#app-container {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	background-color: #111111;
	padding: 30px 0;
	border-radius: 10px 10px 0 0;
}
#logo-holder img {
	height: 60px;
}
#form-wrapper {
	padding: 60px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #FFFFFF;
	text-align: left;
}
#form-wrapper input {
	border: 1px solid #BBB;
	width: calc(100% - 2px);
	/*width: 100%;*/
	display: block;
	padding: 10px;
	font-size: 18px;
}
.form-input-fail {
	padding: 5px 0;
	visibility: hidden;
}
.form-input-fail.shown {
	visibility: visible;
}
.login-button {
	margin-bottom: 20px;
	border-radius: 6px;
	background: #F14E23;
	color: #fff;
	font-size: 15px;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	font-weight: bold;
	transition: ease-in-out 0.5s;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 17px 0;
}
.login-button:hover {
	background: #F86E43;
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	margin: 0 auto;
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
</style>
