<template>
	<div class="tab" :class="activeClass" @click="setActiveTab(title)">
		<span>{{title}}</span>
		<span v-if="showTick" class="tickholder">
			<img src="@/assets/tick.png" alt="Valid selection" />
		</span>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Tab",
	components: {},
	mixins: [globalMixin],
	props: {
		title: String,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		activeTab() {
			return this.store.activeTab;
		},
		activeClass() {
			if(this.activeTab === this.title) return 'active';
			return 'inactive';
		},
		showTick() {
			let at = this.title;
			let rfTotalSpotsComputed = this.getRfTotalSpotsComputed()
			if(at === 'Markets' && this.store.selectionObs.markets.length > 0) return true;
			else if(at === 'Surveys' && this.store.selectionObs.surveys.length > 0) return true;
			else if(at === 'Stations' && (this.store.selectionObs.stations.length > 0 )) return true;
			else if(at === 'Demos' && this.store.selectionObs.demos.length > 0) return true;
			else if(at === 'Statistics' && this.store.selectionObs.statistics.length > 0) return true;
			else if(at === 'Schedule' && rfTotalSpotsComputed > 0) return true;
			return false;
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>
<style>
li.dropdown:focus-visible {
	outline: none;
}
li.dropdown, li.dropdown a {
	outline: none !important;
}
</style>
<style scoped>
.tab {
	border-right: none;
	border-left: none;
	border-bottom: 1px solid transparent;
	width: auto;
	min-width: 130px;
	max-width: 153px;
	padding: 12px 0 9px 0;
	margin-right: 10px;
	bottom: 0;
	font-size: 14px;
	font-family: Roboto;
	font-weight: normal;
	text-transform: none;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
	text-align: center;
	height: 36px;
	position: relative;
	/*transition: all 0.3s ease-in-out;*/
}
.tab.inactive {
	background-color: #DF533C;
	color: #FFF;
	background-image: linear-gradient(to bottom, #EF634C 0%, #DF533C 100%);
	border: none;
}
.tab.inactive:hover {
	background-color: #EF634C;
	background-image: linear-gradient(to bottom, #EF634C 0%, #EF634C 100%);
	color: #FFF;
}
.tab.active {
	background-color: #EFEFEF;
	color: #F04E23;
	background-image: none;
}
.tickholder {
	position: absolute;
	right: 10px;
}
.tickholder img {
	height: 10px;
}
</style>