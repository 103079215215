<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Batch List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter batches..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.batchManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" v-if="batchview === 'main'">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div>
						<span class="primary-button" @click="createNewBatch">Create New</span>
					</div>
					<div v-for="b in filteredBatches" :key="b.id" class="jli" style="display: flex; justify-content: space-between;">
						<div>
							<p class="jname" style="margin-bottom: 5px; text-align: left;">{{b.name}} <span class="jup"> (updated {{b.tstamp}})</span></p>
							<p v-for="(jid, index) in b.jobidarr" :key="index" style="text-align: left; margin-left: 20px; color: #888; font-style: italic;">{{getJobName(jid)}}</p>
						</div>
						<div style="display: flex; justify-content: flex-end; gap: 8px;">
							<span class="jop" @click="runBatch(b)" title="Run batch">RUN</span>
							<span class="jop" @click="copyBatch(b, 'market')" title="Edit batch">COPY-MKT</span>
							<span class="jop" @click="copyBatch(b, 'wave')" title="Edit batch">COPY-WAVE</span>
							<span class="jop" @click="editBatch(b)" title="Edit batch">EDIT</span>
							<span class="jdel" @click="delBatch(b.id)" title="Delete batch">DELETE</span>
						</div>
					</div>
				</div>
			</div>

			<div class="popup-insides" style="text-align: left;" v-if="batchview === 'edit'">
				<div>
					<span style="font-weight: bold;">Batch Name </span>
					<input type="text" v-model="editBatchName" style="width: 300px; padding: 5px; margin-left: 10px;" />
					<span @click="savebatch" class="primary-button" style="margin-left: 10px; margin-right: 10px;">Save & Close</span>
					<span @click="batchview = 'main'" class="primary-button cancel-button">Cancel</span>
				</div>
				<div style="margin: 20px 0;">
					<span style="font-weight: bold;">Select Job </span>
					<select v-model="editBatchSelectJobOb" style="padding: 5px; margin-left: 10px;">
						<option v-for="j in jobslist" :key="j.id" :value="j">{{j.name}}</option>
					</select>
					<span @click="addJobToBatch" class="primary-button" style="margin-left: 10px;">Add</span>
				</div>
				<div style="border-top: 1px solid #DDD; padding: 20px 0;">
					<div v-if="editBatchJobObs.length === 0">
						<p>No jobs in batch yet</p>
					</div>
					<div v-else>
						<p v-for="j in editBatchJobObs" :key="j.id" class="jobitem">
							<span>{{j.name}}</span>
							<span class="jdel" @click="removeJobFromBatch(j.id)" title="Remove job from batch">Remove</span>
						</p>
					</div>
				</div>
			</div>

			<div class="popup-insides" style="text-align: left;" v-if="batchview === 'copy'">
				<div>
					<div style="border-bottom: 1px solid #CCC; padding-bottom: 20px;">
						<span style="font-weight: bold;">Batch Name </span>
						<input type="text" v-model="copyBatchName" style="width: 300px; padding: 5px; margin-left: 10px;" />
					</div>
					<div style="padding: 20px 0; border-bottom: 1px solid #CCC;">
						<p class="bold">Please set new names for the jobs to be copied</p>
						<p v-for="(j, index) in copyBatchJobObs" :key="j.id" style="padding: 10px 0; display: flex; justify-content: space-between; align-items: center;">
							<span>{{j.name}} <span style="color: #BBB;"> copied to </span></span>
							<input type="text" v-model="copyJobNewNames[index]" style="width: 300px; padding: 5px; margin-left: 10px;" />
						</p>
					</div>
					<div style="padding: 20px 0; border-bottom: 1px solid #CCC;" v-if="copyBatchType === 'market'">
						<span class="bold">Select the market for this new batch</span>
						<select v-model="copyBatchMarketSelect" style="padding: 5px; margin-left: 10px;">
							<option v-for="m in store.markets" :key="m.id" :value="m.id">{{m.name}}</option>
						</select>
					</div>
					<div style="padding: 20px 0; border-bottom: 1px solid #CCC;" v-if="copyBatchMarketSelect !== null">
						<p class="bold" style="margin-bottom: 10px;">Select the surveys for the new batch</p>
						<div v-for="sv in filteredSurveys" :key="sv.id" style="padding: 5px 0;">
							<input type="checkbox" v-model="copyBatchSurveyIdsSelect" :value="sv.id" />
							<span style="margin-left: 5px;" class="label">{{sv.name}}</span>
						</div>
					</div>
					<div style="padding: 20px 0; border-bottom: 1px solid #CCC;" v-if="copyBatchMarketSelect !== null">
						<p class="bold" style="margin-bottom: 10px;">Select the stations for the new batch</p>
						<div v-for="sv in copyBatchAvailStations" :key="sv.id" style="padding: 5px 0;">
							<input type="checkbox" v-model="copyBatchStationIdsSelect" :value="sv.id" />
							<span style="margin-left: 5px;" class="label">{{sv.name}}</span>
						</div>
						<p class="bold" style="margin-bottom: 10px; margin-top: 15px;">Combos</p>
						<div v-for="sv in copyBatchAvailStationCombos" :key="sv.id" style="padding: 5px 0;">
							<input type="checkbox" v-model="copyBatchStationIdsSelect" :value="sv.id" />
							<span style="margin-left: 5px;" class="label">{{sv.name}}</span>
						</div>
					</div>

					<div style="padding: 20px 0;">
						<span @click="savebatchFromCopy" class="primary-button" style="margin-right: 10px;">Save</span>
						<span @click="batchview = 'main'" class="primary-button cancel-button">Cancel</span>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "BatchManage",
	components: {},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			batchlist: [],
			jobslist: [],
			filterText: '',
			batchIsRunning: false,

			batchview: 'main',
			editBatchId: 0, //for new
			editBatchName: '',
			editBatchJobObs: [],
			editBatchSelectJobOb: null,

			copyBatchObjectSource: null,
			copyBatchName: '',
			copyBatchJobObs: [],
			copyJobNewNames: [],
			copyBatchType: null,
			copyBatchMarketSelect: null,
			copyBatchSurveyIdsSelect: [],
			copyBatchStationIdsSelect: [],

		}
	},
	computed: {
		filteredBatches() {
			if(this.filterText.length === 0) {
				return this.batchlist;
			}
			else { //either already selected or matching text
				return this.batchlist.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		editBatchJobIds() {
			let jids = [];
			for(let i=0; i<this.editBatchJobObs.length; i++) {
				if(!jids.includes(this.editBatchJobObs[i].id)) {
					jids.push(this.editBatchJobObs[i].id)
				}
			}
			return jids;
		},
		filteredSurveys() {
			return this.store.surveys.filter(item => this.copyBatchMarketSelect === item.mktid);
		},
		copyBatchAvailStations() {
			return this.store.stations.filter(item => this.copyBatchMarketSelect === item.mktid);
		},
		copyBatchAvailStationCombos() {
			return this.store.stationcombos.filter(item => item.mktid === this.copyBatchMarketSelect && item.isSystem === 0);
		},
	},
	methods: {
		removeJobFromBatch(jid) {
			this.editBatchJobObs = this.editBatchJobObs.filter(item => item.id !== jid);
		},
		addJobToBatch() {
			if(this.editBatchSelectJobOb !== null && !this.editBatchJobIds.includes(this.editBatchSelectJobOb.id)) {
				this.editBatchJobObs.push(this.editBatchSelectJobOb);
			}
		},
		createNewBatch() {
			this.editBatchId = 0;
			this.editBatchName = '';
			this.batchview = 'edit';
		},
		editBatch(b) {
			this.batchview = 'edit';
			this.editBatchId = b.id;
			this.editBatchName = b.name;
			let js = [];
			for(let i=0; i<b.jobidarr.length; i++) {
				let job = this.jobslist.find(item => item.id === b.jobidarr[i]);
				if(job) js.push(job);
			}
			this.editBatchJobObs = js;
		},
		copyBatch(b, copytype) {
			this.copyBatchMarketSelect = null; //reset
			this.copyBatchSurveyIdsSelect = []; //reset
			this.copyBatchStationIdsSelect = []; //reset
			this.copyBatchObjectSource = b;
			this.copyBatchName = b.name;
			let js = [];
			let names = [];
			for(let i=0; i<b.jobidarr.length; i++) {
				let job = this.jobslist.find(item => item.id === b.jobidarr[i]);
				if(job) {
					js.push(job);
					names.push(job.name);
				}
			}
			this.copyBatchJobObs = js;
			this.copyBatchType = copytype; //market or wave
			this.copyJobNewNames = names;
			if(copytype === 'wave') {
				this.copyBatchMarketSelect = js[0].marketidArr[0];
				this.copyBatchStationIdsSelect = js[0].stationidArr;
			}
			this.batchview = 'copy';
		},
		savebatchFromCopy() {
			let self = this;
			self.store.showLoader = true;
			let sendob = {
				userob: this.store.user,
				fromBatchId: this.copyBatchObjectSource.id,
				newBatchName: this.copyBatchName,
				jobsToCopy: this.copyBatchObjectSource.jobidarr.join("|"),
				newJobNames: this.copyJobNewNames.join("|"),
				newJobMarketId: this.copyBatchMarketSelect.join("|"),
				newJobSurveyIds: this.copyBatchSurveyIdsSelect.join("|"),
				newJobStationIds: this.copyBatchStationIdsSelect.join("|"),
			};
			self.store.showLoader = true;
			axios.post(
				self.store.apiroot + "batch-save-copy",
				JSON.stringify(sendob)
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
					self.batchview = 'main';
					self.fetchBatchList();
				}
				self.store.showLoader = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		savebatch() {
			if(this.editBatchName.length === 0) {
				alert('Please enter a name for your batch');
				return false;
			}
			if(this.editBatchJobObs.length === 0) {
				alert('Please choose some jobs');
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			axios.post(
				self.store.apiroot + "batch-save",
				JSON.stringify({
					userob: self.store.user,
					bid: self.editBatchId,
					batchname: self.editBatchName,
					jobids: self.editBatchJobIds.join('|'),
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
					self.batchview = 'main';
					self.fetchBatchList();
				}
				self.store.showLoader = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		fetchBatchList() {
			if(this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "batches-get-list",
					JSON.stringify({
						userob: self.store.user,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.jobs) { //handle returned objects for lists
						self.jobslist = ret.jobs;
					}
					if (ret.batches) { //handle returned objects for lists
						self.batchlist = ret.batches;
					}
					self.fetching = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getJobName(jid) {
			let j = this.jobslist.find(item => item.id === jid);
			if(j) return j.name;
			return false;
		},
		runBatch(batchob) {
			if(this.batchIsRunning === false) {
				this.batchIsRunning = true;
				this.store.showLoader = true;
				let jobs = this.jobslist.filter(item => batchob.jobidarr.includes(item.id));
				this.runJobLoop(jobs, 0);
			}
		},
		runJobLoop(jobarr, counter) {
			if(counter < jobarr.length) {
				this.runJob(jobarr[counter]);
				setTimeout(function() {
					counter++;
					this.runJobLoop(jobarr, counter);
				}.bind(this), 5000);
			}
			else { //batch job loop is finished
				this.batchIsRunning = false;
				this.store.showLoader = false;
			}
		},
		runJob(myjob) {
			let layoutOb = this.store.defaultTableLayout; //default
			if(myjob.tableLayoutStr.length > 0) {
				layoutOb = this.convertTableLayoutStringToOb(myjob.tableLayoutStr);
			}
			let targ = this.store.calcEngineRoot + "run-ratings-calcs";
			var sendstring = "tblchoice=" + layoutOb.table + "&rowchoice=" + layoutOb.row +
				"&colChoice=" + layoutOb.col + "&colSplitChoice=" + layoutOb.colsplit +
				"&markets=" + myjob.marketidArr.join("|") + "&surveys=" + myjob.survidArr.join("|") +
				"&stations=" + myjob.stationidArr.join("|") + "&demos=" + myjob.demoidArr.join("|") +
				"&statistics=" + myjob.statisticsidArr.join("|") +
				"&survOldestLeft=" + myjob.survOldestLeft + "&incomingFilename=" + myjob.name + "&vuexcel=1" + "&toExcel=1" +
				"&userid=" +this.store.user.id+ "&useremail=" +this.store.user.email+ "&usertoken=" + this.store.user.token;
			window.location = targ + "?" + sendstring;
		},
		delBatch(bid) {
			let c = window.confirm("Are you sure you wish to delete this batch?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					self.store.apiroot + "batch-delete",
					JSON.stringify({
						userob: self.store.user,
						bid: bid,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					self.fetchBatchList(); //refresh the job list
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {},
	mounted() {
		this.fetchBatchList();
	}
}
</script>

<style scoped>

</style>