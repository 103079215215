<template>
	<div v-if="logourl" class="item-logo">
		<img :src="logourl" />
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "StationLogo",
	components: {},
	mixins: [globalMixin],
	props: {
		stnob: Object,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		logourl() {
			if(this.stnob.logofile.length > 0) {
				return this.store.logosRoot + "station-logos/" + this.stnob.logofile+"?v=2";
			}
			else if(this.stnob.netgroupown.length > 0) { //looking for a network value
				return this.store.logosRoot + "station-logos/"+this.stnob.netgroupown +".png?v=2";
			}
			else return false;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.item-logo {
	width: 100%;
	height: 100%;
}
.item-logo img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
</style>